.seatingtemplate__main_wrap {
    width: 100%;
}

.seatingtemplate__main_wrap .tab-header {
    margin-top: 25px;
}

.seatingtemplate__main_wrap .tab-header .nav-link {
    padding-bottom: 5px;
    display: inline-flex;
    margin-right: 15px;
    text-decoration: none;
    font-size: 16px;
}

.seatingtemplate__main_wrap .tab-header .screentitle__wrapper {
    margin-left: 50px;
    min-height: 60px;
}

.seatingtemplate__main_wrap .tab-header .screentitle__wrapper h2 {
    font-size: 21px;
    font-weight: 700;
    margin-bottom: 0;
}

.seatingtemplate__main_wrap .tab-header .screentitle__wrapper p {
    font-size: 16px;
    margin-bottom: 0;
}

.seatingtemplate__main_wrap .tab-header .nav-link.active {
    color: #00B3E3;
    border-bottom: 3px solid #00B3E3;
}

.seatingtemplate__main_wrap .tab-body {
    margin-top: 15px;
}

.shape {
    justify-content: center;
    align-items: center;
    display: flex;
    margin: 0 auto;
}

.shape.square {
    min-height: 450px;
    width: 450px;
    border-radius: 25px;
    box-sizing: border-box;
    padding: 15px;
}

.shape.circle {
    height: 450px;
    width: 450px;
    border-radius: 450px;
}

.shape.rectangle {
    height: 450px;
    width: 700px;
    border-radius:25px;
}

.shape .shape-content {
    text-align: center;
    font-size: 24px;
    color: #fff;
}

.dropdown-menu {
    border-radius: 15px!important;
    border: 0!important;
    padding: 15px!important;
}

.element-title {
    margin-bottom: 5px;
}

.element-container {
    min-width: 260px!important;
    max-width: 260px!important;
    gap: 10px!important;
}

.element-container .col {
    /* width: 40px; */
    flex: 0;
}

.element-container .dropdown-item {
    width: 35px;
    height: 35px;
    padding: 5px;
    /* background-color: rgb(227, 227, 227, 1)!important; */
    /* border-radius: 5px; */
}

.element-container .element-icon {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.element-container .activeItem {
    /* background-color: #00b3e3ad!important; */
    position: relative;
}

.element-container .activeItem::after {
    content: '';
    background-color: #00b3e35e;
    left: 0;
    top: 0;
    position: absolute;
    width: 35px;
    height: 35px;
    z-index: 1;
    border-radius: 5px;
}

.circle-picker span.active > div {
    border: 1px solid;
    border-radius: 100%;
}

#dropdown-basic::after {
    display: none;
}    

.create__template_form .accordion {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 15px #0000001A;
    border: 1px solid #2C364898;
    border-radius: 8px;
    opacity: 1;
    min-height: 50px;
    line-height: 1;
    margin-bottom: 20px;
}

.create__template_form .accordion .accordion-header {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    padding: 15px;
    align-items: center;
}

.create__template_form .accordion .accordion-header .arrow {
    font-size: 10px;
    line-height: 0;
}
.form__main_template_content {
    display: flex;
    width: 100%;
    column-gap: 50px;
}

.create__template_form {
    width: 100%;
    max-width: 310px;
}

.canvas__wrapper {
    width: 100%;
}

.form__main_template_content h3 {
    font-size: 18px;
    font-weight: 600;
}

.create__template_form label {
    color: #292D41;
    opacity: 0.6;
    font-size: 14px;
}

button.btn__create_template.btn.btn-primary {
    color: #fff;
    font-size: 18px;
    border: 1px solid #00B3E3;
    border-radius: 25px;
    width: 180px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: #00B3E3;
    box-shadow: 0px 4px 16px #00B3E367;
}
.create__template_form select.form-select {
    font-size: 12px;
    min-height: 40px;
}
button.template__accor_edit {
    color: #fff;
    font-size: 18px;
    border: 1px solid #00B3E3;
    border-radius: 25px;
    width: 160px;
    height: 50px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: #00B3E3;
}

button.template__accor_clone {
    padding: 0;
    background: none;
    border: none;
    color: #FF6C36;
    font-size: 16px;
    /* margin-left: 20px; */
}
button.template__accor_delete {
    padding: 0;
    background: none;
    border: none;
    color: #ff0000;
    font-size: 16px;
    /* margin-left: 20px; */
}
.create__template_form .accordion-body.accordion-body-edittemplate {
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.create__template_form .accordion-body {
    margin: 15px;
    border-top: 1px solid #eaeaea;
    padding: 15px 0 0;
}
.canvas__container__custom .canvas__zoom {
    display: flex;
    align-items: center;
}

.canvas__container__custom button.fa.fa-search-plus {
    border: 0;
    height: 30px;
    width: 30px;
    background-color: lightgray;
    border-radius: 30px;
    transition: all 0.2s ease;
    font-size: 15px;
}

.canvas__container__custom button.fa.fa-search-minus {
    border: 0;
    height: 30px;
    width: 30px;
    background-color: lightgray;
    border-radius: 30px;
    transition: all 0.2s ease;
    font-size: 15px;
}

.canvas__container__custom .canvas__zoom select.canvas__zoomselect.form-select {
    width: auto;
    margin: 0 15px;
}

.seatingtemplate__main_wrap a {
    cursor: pointer;
}

.seating-overlay{
    background: #0000000e;
    content: '';
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: center;
}

.seating-overlay i{
    color: #00B3E3;
    font-size: 50px;
}