.adjustment-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #1E27396F 0% 0% no-repeat padding-box;
    opacity: 1;
    backdrop-filter: blur(18px);
    -webkit-backdrop-filter: blur(18px);
    z-index: 999999;
    display: flex;
    justify-content: center;
    align-items: center;
}

.adjustment-popup-content {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 8px 20px #28385033;
    border-radius: 30px;
    width: 100%;
    max-width: 514px;
    height: 100%;
    max-height: 462px;
    padding: 40px;
}
.adjustment-popup-header {
    font-size: 21px;
    color: #292D41;
    font-weight: 800;
    margin-bottom: 15px;
}

.adjustment-popup-body {
    display: flex;
    flex-wrap: wrap;
    column-gap: 46px;
}

.adjustment-popup-field-wrap.referNumber {
    width: 50%;
    max-width: 194px;
}
.adjustment-popup-field-wrap.referNumber label {
    display: flex;
    flex-wrap: wrap;
}

.adjustment-popup-field-wrap.referNumber span {
    width: 100%;
}





.adjustment-popup-field-wrap.amount {
    width: 50%;
    max-width: 194px;
}
.adjustment-popup-field-wrap.amount label {
    display: flex;
    flex-wrap: wrap;
}

.adjustment-popup-field-wrap.amount span {
    width: 100%;
}


.adjustment-popup-body input {
    border: 1px solid #292D4180;
    border-radius: 8px;
    width: 100%;
    height: 40px;
    font-size: 12px;
    text-indent: 15px;
}

.adjustment-popup-body label {
    width: 100%;
    color: #292D41;
    opacity: 0.6;
    font-size: 14px;
}

.adjustment-popup-field-wrap.description__field {
    width: 100%;
    margin-top: 32px;
    margin-bottom: 20px;
}

.adjustment-popup-field-wrap.description__field span {
    width: 100%;
}

.adjustment-popup-field-wrap.description__field label {
    display: flex;
    flex-wrap: wrap;
}

.adjustment-popup-field-wrap.description__field textarea {
    width: 100%;
    border: 1px solid #292D4180;
    border-radius: 8px;
    min-height: 71px;
}

.adjustment-popup-field-upload {
    width: 100%;
    margin-bottom: 15px;
}

.adjustment-popup-footer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

button.adjustment-popup-submit {
    color: #fff;
    font-size: 18px;
    border: 1px solid #00B3E3;
    border-radius: 25px;
    width: 180px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: #00B3E3;
}

button.adjustment-popup-cancel {
    border: none;
    padding: 0;
    background-color: transparent;
}
.adjustment-popup-content label span {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.adjustment-popup-content label span em {
    font-style: normal;
}
.adjustment-popup-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
}
.diabledButton{
    opacity: 0.65;
    pointer-events: none;
}