.ticket__print_wrap {
    width: 100%;
    max-width: 1307px;
    margin: 0 auto;
}

.ticket__print_wrap_header {
    text-align: center;
}

.ticket__print_wrap_header img {
    max-width: 206px;
}

.ticket__print_wrap_header_title {
    font-size: 52px;
    font-weight: 700;
}

.ticket__print_wrap_body_ticket_info {
    display: flex;
    justify-content: space-between;
    margin-top: 80px;
}

.ticket__print_wrap_body_left_cont_list {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
}

.ticket__print_wrap_cont_list_text {
    font-size: 30px;
    color: #292D41;
}

.ticket__print_wrap_cont_list_label {
    min-width: 180px;
    font-size: 25px;
    color: #292D41;
    font-weight: 600;
}

.ticket__print_wrap_body_right_cont_text {
    text-align: center;
    font-size: 30px;
    color: #EC2539;
}

.ticket__print_wrap_body_right_cont_qr img {
    width: 100%;
    max-width: 300px;
    min-width: 270px;
}


.table-container-ticket-print {
    width: 100%;
    max-width: 1307px;
    margin: 60px auto;
}

table.data-table-ticket-print {
    width: 100%;
}

table.data-table-ticket-print thead {
    border: none;
    border-radius: 26px;
    background-color: #f2f2f2;
}

table.data-table-ticket-print thead th {
    font-size: 30px;
    border: none;
    background: #fafafa;
    padding: 15px;
}


table.data-table-ticket-print tbody td {
    font-size: 40px;
    border: none;
    padding: 15px;
}

.ticket__print_sponser_main_wrap {
    margin-top: 110px;
}

.ticket__print_sponser_title {
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 35px;
}

.ticket__print_terms_content_wrap {
    margin-top: 70px;
}

.ticket__print_terms_content_title {
    font-size: 32px;
    margin-bottom: 26px;
    font-weight: 600;
}

.ticket__print_terms_content p {
    font-size: 18px;
}