.filter__right_box {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  column-gap: 30px;
}
.footer__reserved_page {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 37px;
}
.footer__reserved_page .estimation {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.footer__reserved_page .estimation h4 {
  font-size: 15px;
  font-weight: 600;
  margin: 0;
  margin-right: 7px;
}
.footer__reserved_page .estimation p {
  margin: 0;
  font-size: 17px;
  color: #ff6c36;
  margin-right: 20px;
}
.footer__reserved_page .action-box {
  display: flex;
  justify-content: flex-end;
  column-gap: 10px;
  align-items: center;
}

.btn_box_btn_ureserved {
  color: #00b3e3;
  font-size: 18px;
  border: 1px solid #00b3e3;
  border-radius: 25px;
  width: 180px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: transparent;
}

.btn_box_btn_buy_ticket {
  color: #fff;
  font-size: 18px;
  border: 1px solid #00b3e3;
  border-radius: 25px;
  width: 180px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: #00b3e3;
}

.discount_create_pop_main_wrap.reservedpageWrap .discount__field_wrap_body {
  width: 100%;
}

.discount_create_pop_main_wrap.reservedpageWrap .cash__form_left_fields {
  width: 100%;
}

.discount_create_pop_main_wrap.reservedpageWrap .input-element {
  max-width: 100%;
}

.discount_create_pop_main_wrap.reservedpageWrap input {
  max-width: 100%;
}

.discount_create_pop_main_wrap.reservedpageWrap
  .country-container.inputformPhone
  input[type="text"] {
  max-width: 365px;
}

.discount_create_pop_main_wrap.reservedpageWrap .discount__field_wrap_footer {
  margin-top: 60px;
}
.pop-up-reserved-close {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.data-table-wrapper {
  max-height: 500px;
  overflow-y: auto;
}
.disabledButton {
  pointer-events: none;
  opacity: 0.65;
}
