.order__detaials_main_wrap {
  width: 100%;
}

.user__orderDetails_wrap {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 32px;
  margin-bottom: 70px;
}

ul.user__orderDetails_list {
  padding: 0;
  list-style: none;
  margin: 0;
  display: inline-flex;
  flex-wrap: wrap;
}

ul.user__orderDetails_list li {
  width: 33%;
  display: inline-flex;
  justify-content: space-between;
  font-size: 14px;
  line-height: 1;
  align-items: center;
  margin-bottom: 16px;
}

ul.user__orderDetails_list li strong {
  min-width: 160px;
}

.printInvoice__btn {
  color: #00b3e3;
  font-weight: 100;
  border: 1px solid #00b3e3;
  border-radius: 25px;
  height: 40px;
  display: inline-flex;
  align-items: center;
  padding: 0 20px;
  margin-left: 30px;
  font-size: 18px;
  line-height: 1;
}

.user__detail_col_box {
  display: flex;
  width: 70%;
}

.user_img_wrap img.profile-picture {
  width: 80px;
  height: 80px;
  margin-right: 30px;
}

.order__detaials_header h2 {
  color: #292d41;
  font-size: 21px;
  font-weight: 700;
}

.order__detaials_header h2 span {
  color: #00b3e3;
  display: inline-flex;
  margin-right: 10px;
  cursor: pointer;
}

.btn_box_btn_print_ticket {
  color: #00b3e3;
  font-weight: 100;
  border: 1px solid #00b3e3;
  border-radius: 25px;
  height: 40px;
  display: inline-flex;
  align-items: center;
  padding: 0 20px;
  font-size: 18px;
  line-height: 1;
}

.btn_box_btn_resend_ticket {
  color: #ff6c36;
  font-weight: 100;
  border: 1px solid #ff6c36;
  border-radius: 25px;
  height: 40px;
  display: inline-flex;
  align-items: center;
  padding: 0 20px;
  margin-left: 30px;
  font-size: 18px;
  line-height: 1;
}

.total__cal_col ul {
  padding: 0;
  list-style: none;
  display: flex;
  column-gap: 30px;
}
.total__cal_col ul li strong {
  color: #ff6c36;
  font-size: 21px;
  display: inline-flex;
  margin-left: 15px;
}

.total__cal_col ul li {
  font-size: 14px;
}

.header__main_box h2 {
  font-size: 21px;
  color: #292d41;
  font-weight: 700;
}

.header__main_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;
}

.cancelBtn_ticket {
  background: #00b3e3;
  color: #fff;
  font-size: 15px;
  padding: 7px 16px;
  border-radius: 5px;
  font-weight: 500;
  cursor: pointer;
}
.cancelBtn_ticket.cancelled {
  background: #df0000;
  cursor: auto;
}

.tickets__details_wrap table {
  width: 100%;
  margin-bottom: 30px;
}

.tickets__details_wrap table th,
.tickets__details_wrap table td {
  text-align: center;
  padding: 10px;
}
.tickets__details_wrap table th:first-child,
.tickets__details_wrap table td:first-child {
  text-align: left;
}

.tickets__details_wrap table th:last-child,
.tickets__details_wrap table td:last-child {
  text-align: right;
}

.ticket__detail_footer {
  display: flex;
  justify-content: space-between;
}
