.orderSummary__pop_detail {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    z-index: 9999;
}

.orderSummary__pop_detail_overlay {
    background: #1E27396F 0% 0% no-repeat padding-box;
    opacity: 1;
    backdrop-filter: blur(18px);
    -webkit-backdrop-filter: blur(18px);
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
}

.orderSummary__pop_detail__container {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 10px 25px #575D6F34;
    border-radius: 20px;
    opacity: 1;
    position: relative;
    padding: 36px;
    min-width: 705px;
    height: 750px;
    overflow-y: scroll;
}

.orderSummary__pop_detail__container::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  .orderSummary__pop_detail__container::-webkit-scrollbar-track {
    background: #e2e2e2;
  }
  .orderSummary__pop_detail__container::-webkit-scrollbar-thumb {
    background: #9E9E9E;
  }
  .orderSummary__pop_detail__container::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  

.orderSummary__pop_detail_header {
    text-align: center;
    margin-bottom: 44px;
}

.orderSummary__pop_detail_header img {
    width: 100px;
    height: 100px;
}

.orderSummary__pop_detail_header h3 {
    font-size: 30px;
    margin: 0;
    font-weight: 700;
    color: #292D41;
    margin-bottom: 14px;
    margin-top: 15px;
}

.orderSummary__pop_detail_header p {
    font-size: 16px;
    color: #999B9F;
    max-width: 447px;
    margin: 0 auto;
}

.orderSummary__pop_detail_order_cost {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 45px;
}

.orderSummary__pop_detail_order_costtitle {
    font-size: 25px;
    color: #292D41;
    font-weight: 600;
}

.orderSummary__pop_detail_order_costprice {
    font-size: 14px;
    color: #292D41;
}

.orderSummary__pop_detail_order_costprice span {
    font-size: 21px;
    color: #FF6C36;
    padding-left: 15px;
}

.orderSummary__pop_detail_table_list {
    width: 100%;
}

.orderSummary__pop_detail_table_list table {
    width: 100%;
    margin-bottom: 63px;
}

.orderSummary__pop_detail_table_list table th,.orderSummary__pop_detail_table_list table td {
    padding: 15px;
}

.orderSummary__pop_detail_footer_btn {
    display: flex;
    justify-content: flex-end;
}

button.print_btn_ticket {
    color: #00B3E3;
    font-weight: 100;
    border: 1px solid #00B3E3;
    border-radius: 25px;
    height: 50px;
    display: inline-flex;
    align-items: center;
    padding: 0 20px;
    font-size: 18px;
    line-height: 1;
}

button.print_btn_invoice {
    color: #00B3E3;
    font-weight: 100;
    border: 1px solid #00B3E3;
    border-radius: 25px;
    height: 50px;
    display: inline-flex;
    align-items: center;
    padding: 0 20px;
    font-size: 18px;
    line-height: 1;
}

button.book__next_btn {
    color: #fff;
    font-size: 18px;
    border: 1px solid #00B3E3;
    border-radius: 25px;
    width: 180px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: #00B3E3;
}