.banner-edit-wrapper {
  flex-direction: column;
  gap: 15px;
  .save__bt_publish {
    align-self: flex-end;
  }
  .banners-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;
    width: 100%;
    flex-wrap: wrap;
    .banner-item {
      display: flex;
      width: 300px;
      height: 160px;
      border: 1px solid #d7d7d7;
      border-radius: 12px;
      overflow: hidden;
      flex-direction: column;
      position: relative;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      span {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(255, 0, 0, 0.1);
        height: 30px;
        width: 30px;
        border-radius: 6px;
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;
      }
    }
  }
}
