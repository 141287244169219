a.canvasjs-chart-credit {
  display: none !important;
}

.feedback_page_header {
  width: 100%;
}

.feedback_page_header h2 {
  font-size: 21px;
  font-weight: 700;
  margin-bottom: 27px;
}

.feedback__tab_wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.feedback__tab_wrapper input[type="date"] {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.feedback__tab_tab_buttons button {
  border: none;
  padding: 0;
  border-bottom: 1px solid transparent;
  color: #292D41;
  background: transparent;
}

.feedback__tab_tab_buttons {
  width: 100%;
  display: flex;
  column-gap: 40px;
}

.feedback__tab_tab_buttons button.active {
  color: #00B3E3;
  border-bottom-color: #00B3E3;
}

.feedback_row_wrap {
  width: 100%;
  margin-top: 35px;
}


.feedback_row_main {
  display: flex;
  align-items: center;
  padding: 15px 0px;
  border-bottom: 1px solid #292d4138;
}

.feedback_row_main .email_text {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 20px;
  /* padding-left: 25px; */
  text-decoration: none;
  justify-content: space-between;
}


.feedback_row_checkbox_col input[type="checkbox"] {
  accent-color: #00B3E3;
  color: #fff;
}

.feedback_row_image_info {
  display: flex;
  align-items: center;
}



img.feedback_row_image_info_profile_picture {
  width: 30px;
  height: 30px;
  border-radius: 30px;
}

.feedback_row_image_info_name {
  font-size: 16px;
  color: #292D41;
  text-decoration: none;
  line-height: 1;
}

.feedback_row_image_info_email {
  font-size: 12px;
  color: #292D41;
}

.feedback_row_image_info_name_email {
  padding-left: 7px;
  padding-right: 7px;
}

.feedback_row_content_col {
  width: 80%;
  font-size: 16px;
  color: #292D41;
}

.date_trash_container_date {
  font-size: 16px;
  color: #292D41;
}
img.feedback_page_profile_picture {
  width: 50px;
  border-radius: 50px;
}

.feedback_page_content_details {
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.feedback_page_profile_name {
  font-size: 16px;
  color: #292D41;
  font-weight: 600;
}

.feedback_page_content_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.feedback_page_profile_username {
  font-size: 12px;
}

.feedback_page_profile_subject {
  font-weight: 600;
}

.feedback_page_profile_text_content {
  padding-left: 60px;
  font-size: 15px;
}

span.feedback_page_profile_date_container {
  font-size: 16px;
  font-weight: 100;
}

span.feedback__page_icon {
  font-size: 12px;
  color: #FF6C36;
  margin-left: 30px;
  font-weight: 100;
  display: inline-flex;
  align-items: center;
}

span.feedback__page_icon img {
  margin-right: 5px;
}

.feedback_page_action_buttons {
  display: flex;
  align-items: center;
}
.feedback_replyBox_img_replyBox {
  display: flex;
  align-items: flex-start;
  width: 100%;
}

img.feedback_replyBox_profile_picture {
  width: 50px;
  border-radius: 50px;
}


.feedback_replyBox_wrapper {
  width: 100%;
  margin-top: 60px;
}

.feedback_quill_container {
  width: 100%;
  border: 1px solid #707070;
  border-radius: 24px;
  margin-left: 10px;
  padding: 20px;
}

button.feedback_replyBox_submitBtn {
  color: #fff;
  font-size: 18px;
  border: 1px solid #00B3E3;
  border-radius: 25px;
  width: 180px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: #00B3E3;
}

.feedback_replyBox_footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  border-top: 1px solid #7070703b;
  padding-top: 30px;
}
.feedback_page_content {
  width: 100%;
}
.feedback_page_reply {
  width: 100%;
  padding: 40px 0px 0px 60px;
}