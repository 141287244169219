.tickets-container {
  width: 100%;
}
.filter-options {
  display: flex;
  gap: 60px; /* Adjust the spacing between filters as needed */
  align-items: center;
  margin-left: 30%; /* Align items vertically in the center */
  /* Align items vertically in the center */
}

.filter-options h6 {
  margin: 0;
  padding-right: 10px; /* Add some spacing between label and filter element */
}

.filter-options select,
.filter-options input {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.page__container_wrapper {
  display: flex;
  margin-left: 290px;
  flex-wrap: wrap;
  background: #fff;
  width: calc(100% - 325px);
  padding: 29px 40px;
  margin-bottom: 60px;
  border-radius: 0 0 30px 30px;
}

.page__container_wrapper.ticket__page .filter-options {
  width: 100%;
  margin: 0;
  text-align: left;
  padding-bottom: 30px;
  padding-top: 20px;
}

.page__container_wrapper.ticket__page .filter-options h2 {
  width: 29%;
}
.page__container_wrapper.ticket__page .filter-options h2,
.filter-title {
  font-size: 21px;
  font-weight: 700;
}
.filter__option_col {
  display: flex;
  align-items: center;
}

.filter__option_col h6 {
  font-size: 12px;
  font-weight: 600;
  min-width: fit-content;
}

.filter__option_col select {
  min-width: 160px;
  font-size: 12px;
  height: 30px;
  padding: 0 5px;
}

.page__container_wrapper .table-container table.data-table {
  border: none;
}

.page__container_wrapper .table-container table.data-table thead {
  border: none;
  border-radius: 26px;
  background-color: #f2f2f2;
}

.page__container_wrapper .table-container table.data-table tr {
  border: none;
}

.page__container_wrapper .table-container table.data-table th {
  font-size: 12px;
  border: none;
  background: transparent;
  padding: 15px;
}

.page__container_wrapper .table-container table.data-table tr td {
  padding: 20px 15px;
}
.filter-options.member_filter {
  justify-content: space-between;
}
