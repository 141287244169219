.editor-container {
  display: flex;
  justify-content: center;
  border: 1px solid #ccc;
  padding: 10px;
  width:55%;
  margin-left: 25%;
  
}

.editor-container button {
  background: none;
  border: none;
  margin-right: 10px; 
}
.container-save {
  position: relative; 
  width: 100%; 
  height: 300px; 
}

.save-publish-button {
  background-color: rgb(48, 186, 240); 
  color: white; 
  border: none; 
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 30px; 
}

.save-publish-button:hover {
  background-color: deepskyblue; /* Change button color on hover if desired */
}

.editor__main_container_wrap {
  display: flex;
  padding-left: 230px;
  position: relative;
  flex-wrap: wrap;
  align-items: flex-start;
}

.editor__main_container_wrap h3 {
  width: 100%;
  margin: 0;
}

.editor__main_container_wrap .editor-container-main {
  width: 100%;
  border: 1px solid #707070;
  border-radius: 26px;
  padding: 8px 20px;
  margin-top: 30px;
}

.editor__main_container_wrap .editor-container {
  width: 100%;
  margin: 0;
  border: none;
  justify-content: flex-start;
}

.editor__main_container_wrap .editor-container-main .DraftEditor-root {
  min-height: 60vh;
}


.container-save {
  height: fit-content;
  text-align: right;
  padding: 23px 0;
}

.DraftEditor-editorContainer {
  height: 60vh;
}

.page__container_wrapper.policy__page {
  flex-wrap: wrap;
}

.editor__main_wrap {
  width: 100%;
}

.editor__main_wrap .ql-container.ql-snow {
  min-height: 60vh;
  margin-bottom: 30px;
  cursor: text;
}

button.savnpublish__btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 61px;
  border-radius: 30px;
  border: 1px solid rgb(61, 187, 237);
  color: #fff;
  font-size: 18px;
  background: #00B3E3;
  font-weight: 100;
  margin-left: auto;
  margin-top: 40px;
}

button.savnpublish__btn:hover {
  background: #fff;
  color: #00B3E3;
}

.ql-editor.ql-blank {
  height: 100%;
  min-height: 60vh;
}

.page__container_wrapper.policy__page>h2 {
  font-size: 21px;
  font-weight: 600;
  margin-bottom: 23px;
}
.ql-toolbar.ql-snow {
  border: none;
}

.ql-container.ql-snow {
  border: none;
}

.editor__main_wrap .quill {
  border: 1px solid #707070;
  border-radius: 24px;
  opacity: 1;
  padding: 20px;
}