.report__page_bodyContain {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  column-gap: 20px;
}

.report__page_header h2 {
  color: #292d41;
  font-size: 21px;
  font-weight: 700;
  margin-bottom: 32px;
}

.report__page_fieldset {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 316px;
  margin-bottom: 30px;
}

.report__page_fieldset label {
  color: #292d41;
  font-size: 14px;
  opacity: 0.6;
  padding: 0;
  min-width: 240px;
  width: 100%;
}

.report__page_fieldset input,
.report__page_fieldset select {
  border: 1px solid #292d4180;
  border-radius: 8px;
  color: #292d41;
  font-size: 12px;
  width: 100%;
  height: 40px;
  padding: 0 5px;
  margin-top: 3px;
}
.report__page_fieldset input[type="checkbox"] {
  width: 20px;
  height: 20px;
  margin-top: 0;
  margin-right: 5px;
}

.report__page_fieldset ul.suggestions {
  padding: 0;
  list-style: none;
  position: absolute;
  right: 0;
  top: 105%;
  width: 183px;
  padding: 3px 8px;
  border: 1px solid #292d4180;
  border-radius: 9px;
  margin-top: -4px;
  font-size: 12px;
  background: #e5e5e5;
}

.report__page_fieldset ul.suggestions li {
  border-bottom: 1px solid #dbdbdb;
  padding: 2px 0;
}

.report__page_fieldset ul.suggestions li:last-child {
  border: none;
}

.report__page_fieldset_btn_submit {
  width: 100%;
}

.report__page_fieldset_btn_submit button {
  color: #fff;
  font-size: 18px;
  border: 1px solid #00b3e3;
  border-radius: 25px;
  width: 184px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: #00b3e3;
}
.report__page_fieldset.date__fields {
  max-width: 150px;
}
.disbaledButton {
  pointer-events: none;
  opacity: 0.65;
}
