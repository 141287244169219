.image-uploader {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px dashed #ccc;
  border-radius: 10px;
  padding: 20px;
  cursor: pointer;
}

.image-uploader.drag-over {
  background-color: #f0f0f0;
}

.image-uploader input[type="file"] {
  display: none;
}

.image-uploader img {
  max-width: 100%;
  max-height: 300px;
  object-fit: cover;
}

.image-uploader p {
  margin: 0;
}
