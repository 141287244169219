.chart1, .chart2{
    display: inline-block;
}

.appbody__chart_wrap {
    display: flex;
    justify-content: center;
    column-gap: 50px;
    margin-bottom: 60px;
}

.appbody__chart_left_col {
    width: 50%;
    max-width: 490px;
}

.appbody__chart_right_col {
    width: 50%;
    max-width: 490px;
}

.appbody__chart_wrap canvas {
    margin-top: 60px;
}
.appbody__table_wrap.homePage {
    margin-top: 50px;
}
.header__main_wrap .live-feed span {
    padding-top: 5px;
    padding-bottom: 5px;
}