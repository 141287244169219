.page__container_wrapper{
    margin-top: 0;
    border-radius: 0 0 30px 30px;
}
.payment__vander_main .heading-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-bottom: 25px;
    border-bottom: 1px solid #292d411f;
    align-items: center;
    margin-bottom: 30px;
}

h2.payment-heading {
    font-size: 21px;
    color: #292D41;
    font-weight: 600;
    margin: 0;
}

.payment__vander_main  .payment-form {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    /* column-gap: 70px; */
    height: calc(100vh - 250px);
    justify-content: center;
}