/* LiveFeedPopup.css */
.popup-background {
    background: #1E27396F 0% 0% no-repeat padding-box;
    opacity: 1;
    backdrop-filter: blur(18px);
    -webkit-backdrop-filter: blur(18px);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99999;
  }
  
  /* livefeed.css */


  
  .popup-content {
    background: white;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    max-width: 600px; 
    width: 100%;
  }
  
  h2 {
    font-size: 20px;
    margin-bottom: 20px;
  }
  
  .input-row {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }
  
  .input-row label {
    width: 30%;
    text-align: right;
    margin-right: 10px;
  }
  
  .input-row input[type="text"] {
    width: 70%;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 3px;
  }
  
  .input-row input[type="file"] {
    width: 70%;
    padding: 5px;
  }
  
  .input-row img {
    max-width: 100px; 
    margin-top: 10px;
  }
  
  .button-row {
    display: flex;
    margin-top: 20px;
    margin-left:60%;
  }
  
  .button-row button {
    padding: 10px 20px;
    background-color: #007bff; 
    color: white;
    border: none;
    border-radius: 20px;
    cursor: pointer;
  }
  
  .button-row button:hover {
    background-color: #0056b3; 
  }
  
  .event__creation__eventphoto_upload_file_image_uploaded img.file__img_uploaded{
    object-fit: cover;
    width: 100%;
    height: 100%;
    margin: 0;
}
.event__creation__eventphoto_upload_file_image_uploaded {border: 1px dashed #00B3E3;
  width: 146px;
  height: 146px;
  position: relative;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
  position: relative;}


  .popup-background.live__feed_pop h2 {
    font-size: 21px;
    font-weight: 700;
    text-align: left;
    margin-bottom: 17px;
}

.popup-background.live__feed_pop .input-row {
    display: flex;
    flex-wrap: wrap;
}

.popup-background.live__feed_pop .input-row label {
    text-align: left;
    color: #292D41;
    opacity: 0.6;
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 7px;
}

.popup-background.live__feed_pop .input-row input[type="text"] {
    border: 1px solid #a3a3a380;
    border-radius: 8px;
    height: 50px;
    font-size: 14px;
    width: 100%;
}

.live__feed_pop_first_list {
    display: flex;
    column-gap: 20px;
}

.live__feed_pop_content_left__col {
    width: 100%;
    max-width:365px;
}

.live__feed_pop_content_input_row {
    width: 100%;
    max-width: 150px;
}

.live__feed_pop_content_input_row .dropzone {
    width: 100%;
}
.event__creation__eventphoto_upload_file_upload {
  border: 1px dashed #00B3E3;
  width: 146px;
  height: 146px;
  position: relative;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
  position: relative;
}

.event__creation__eventphoto_upload_file_upload:after {
  content: '+';
  color: #00B3E3;
  border: 1px solid #00B3E3;
  width: 40px;
  height: 40px;
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  font-size: 30px;
}
.live__feed_pop_content_input_row label {
  width: 100%;
  text-align: left;
  color: #292D41;
  opacity: 0.6;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 7px;
}
.popup-background.live__feed_pop .popup-content{
  padding: 40px;
}

button.live__feed_pop_cancel_button {
  background: transparent  !important;
  color: #292D41  !important;
  padding: 0  !important;
  margin: 0  !important;
  font-size: 18px;
  font-weight: 700;
  margin: 0 !important;
}

button.live__feed_pop_submit_button {
  padding: 0;
  margin: 0;
  color: #fff;
  font-size: 18px;
  border: 1px solid #00B3E3;
  border-radius: 25px;
  width: 180px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: #00B3E3;
  margin: 0 !important;
}

.buttonrow_live__feed_pop {
  display: flex;
  justify-content: flex-end;
  column-gap: 41px;
  margin-top: 25px;
}