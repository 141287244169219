@import url("assets/css/fonts.css");
@import url(https://db.onlinewebfonts.com/c/2d98f490df6dc039774b101701ce3aba?family=CircularStd-Book);
body * {
  font-family: "CircularStd-Book";
}

.bodyLogin {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-left: 50px;
  min-height: 100vh;
  position: relative;
  z-index: 0;
}
.bodyLogin:after {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 60%;
  height: 100%;
  display: block;
  content: "";
  background-image: url(assets/media/stripes.svg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center left;
  z-index: 1;
}
.bodyLogin:before {
  position: absolute;
  bottom: 0;
  right: 0;
  display: block;
  content: "";
  width: 570px;
  height: 280px;
  background-image: url(assets/media/logo-watermark.svg);
  background-repeat: no-repeat;
  z-index: 2;
}
.landingWrapper .logo {
  margin: 0 0 10px;
}
.landingWrapper h1 {
  font-family: "Circular";
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: #ff6c36;
  margin: 0 0 40px;
  padding: 0;
}
.landingWrapper .landingRow {
  display: flex;
  flex-direction: row;
  margin: 0 -15px;
}
/* .landingWrapper .landingRow .landingCol {
    width: 50%;
    padding: 80px 15px;
} */
.landingWrapper label {
  font-family: "Circular";
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #292d41;
  margin: 0 0 10px;
  padding: 0;
  display: block;
}
.landingWrapper .form-class {
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #292d4180;
  font-family: "Circular Book";
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #292d41;
  padding: 15px;
  margin: 0;
  width: 100%;
}
.landingWrapper .form-class-2 {
  width: 50px !important;
  margin-left: 15px;
}
.landingWrapper .form-class-2:nth-child(1) {
  margin-left: 0;
}
.landingWrapper .fieldWidIcon {
  position: relative;
  display: flex;
  align-items: center;
}
.landingWrapper .fieldWidIcon .form-class {
  padding-right: 30px;
}
.landingWrapper .fieldWidIcon .fieldIcon {
  position: absolute;
  right: 20px;
  cursor: pointer;
}

.landingWrapper .submitButton {
  width: 100%;
  background: #00b3e3;
  border: none;
  border-radius: 30px;
  font-family: "Circular";
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: #fff;
  padding: 15px;
  margin: 0;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.landingWrapper .submitButton:hover {
  box-shadow: 0px 4px 16px #00b3e367;
}
.landingWrapper .submitButton:disabled {
  background: #00b3e3;
  opacity: 0.5;
}
.landingWrapper .fieldWrap {
  width: 300px;
}
.landingWrapper .fieldWrap + .fieldWrap {
  margin-top: 20px;
}
.landingWrapper .fieldWrap.fieldOnFooter {
  margin-top: 30px;
}
.landingWrapper .fieldWrap.fW2col {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.landingWrapper .fieldWrap .field-4-col {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.landingWrapper .fieldWrap .field-4-col .form-class {
  width: 50px;
  height: 50px;
  font-family: "Circular";
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  padding: 10px;
}
.landingWrapper .fieldWrap .field-4-col .form-class::-webkit-outer-spin-button,
.landingWrapper .fieldWrap .field-4-col .form-class::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.landingWrapper .fieldWrap .field-4-col .form-class[type="number"] {
  -moz-appearance: textfield;
}

.landingWrapper .login-page-link {
  font-family: "Circular Book";
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #ff6c36;
  text-decoration: none;
  margin: 0;
  padding: 0;
  display: block;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.landingWrapper .login-page-link:hover {
  text-decoration: underline;
}

.landingWrapper .errorMessage {
  font-family: "Circular Book";
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: red;
  padding: 10px 0 0 10px;
  margin: 0;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.landingWrapper p {
  font-family: "Circular Book";
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #292d41;
  width: 100%;
  max-width: 310px;
  margin: 0 0 15px;
  padding: 0;
}
.landingWrapper h5 {
  position: relative;
  z-index: 0;
  width: 100%;
  max-width: 300px;
  text-align: center;
  margin: 0;
  padding: 0;
}
.landingWrapper h5:after {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  display: block;
  content: "";
  background-color: #00b3e3;
  width: 100%;
  height: 1px;
  z-index: 1;
}
.landingWrapper h5 span {
  background-color: #fff;
  padding: 0 10px;
  font-family: "Circular";
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: #00b3e3;
  position: relative;
  z-index: 2;
}
.App .form-check {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  min-height: 16px;
}
.App .form-check .form-check-input {
  margin: 0;
  padding: 0;
  width: 18px;
  height: 18px;
  display: block;
  border-color: #00b3e3;
  border-width: 2px;
}
.App .landingWrapper label.form-check-label {
  font-family: "Circular Book";
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #292d41;
  margin: 0;
  padding: 0;
}
.App .form-check .form-check-input + label {
  margin-left: 5px;
}
.App .form-check-input:checked {
  background-color: #00b3e3;
  border-color: #00b3e3;
}
.App .form-check-input:focus {
  box-shadow: none;
}
/* for dashboard */
/* Header.css */

.search-bar {
  flex: 1;
  background-color: white;
  height: 30px;
  margin: 0 20px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 0 10px;
}

.popup-button,
.notification,
.person-icon {
  margin: 0 10px;
  cursor: pointer;
}

.canvas__wrapper {
  background: #f9f9fc 0% 0% no-repeat padding-box;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  height: 700px;
  padding-bottom: 25px;
  padding-left: 25px;
  padding-right: 25px;
  overflow: auto;
  width: 100%;
}

.canvas__wrapper::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
.canvas__wrapper::-webkit-scrollbar-track {
  background: #e2e2e2;
}
.canvas__wrapper::-webkit-scrollbar-thumb {
  background: #9e9e9e;
}
.canvas__wrapper::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.canvas__wrapper .sample-canvas {
  /* height: 1600px;
  width: 2560px; */
  height: 1150px;
  width: 3950px;
}

.cursor-pointer {
  cursor: pointer;
}

.element-dropdown-toggle {
  width: 35px;
  height: 35px;
  padding: 5px !important;
  /* background-color: rgb(227, 227, 227, 1)!important; */
  /* border-radius: 5px; */
  margin-right: 5px;
}

.element-dropdown-toggle .element-icon {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.canvas__container {
  margin-left: 50px;
}

.canvas__container .canvas__wrapper .canvas__panel {
  height: 590px;
  overflow: auto;
  display: flex;
  align-items: center;
}

.canvas__container .canvas__navigation {
  background: #f9f9fc 0% 0% no-repeat padding-box;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  padding-top: 25px;
  padding-left: 25px;
  padding-right: 25px;
  width: 100%;
}

.canvas__container .canvas__navigation .dropdown-menu {
  min-width: 300px;
}

.canvas__container .canvas__navigation .canvas__zoom {
  display: flex;
  align-items: center;
}

.canvas__container .canvas__navigation .canvas__zoom .fa {
  border: 0;
  height: 30px;
  width: 30px;
  background-color: lightgray;
  border-radius: 30px;
  transition: all 0.2s ease;
  font-size: 15px;
}

.canvas__container .canvas__navigation .canvas__zoom .fa:hover {
  background-color: #00b3e3;
  color: #fff;
}

.canvas__container .canvas__navigation .canvas__zoom .canvas__zoomselect {
  width: auto;
  margin: 0 15px;
}

.create-new .addmore {
  color: #00b3e3;
  background-color: transparent;
  border: 0;
  outline: 0;
}

/* .canvas-container {
  width: 9500px !important;
  height: 3000px !important;
} */

/* .canvas-container {
  overflow: auto !important;
  border: 1px solid #ccc !important;
  height: initial !important;
  width: initial !important;
  position: initial !important;
} */

/* canvas {
  height: 600px !important;
  width: 600px !important;
} */

.canvas,
.canvas-container {
  height: 100% !important;
  width: 100% !important;
}

.view-only button,
.view-only .addnew__newsUpdate_btn,
.view-only .addnew__newsUser_btn {
  pointer-events: none !important;
  opacity: 0.65 !important;
  cursor: not-allowed !important;
}

.view-only input {
  opacity: 0.5 !important;
  pointer-events: none;
  cursor: not-allowed;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  appearance: textfield;
  -moz-appearance: textfield;
}
.phone-input {
  background: #fff;
  border: 1px solid #7c7c7c;
  border-radius: 5px;
  padding: 5px 10px;
}
.phone-input input {
  background: transparent;
  border: none;
  outline: none;
}
