.faq__page_main_wrap {
  width: 100%;
}

.faq__page_main_header h2 {
  font-size: 21px;
  font-weight: 700;
  margin-bottom: 30px;
}

.faq__page_body {
  display: flex;
  align-items: flex-start;
  column-gap: 40px;
  flex-wrap: wrap;
}

.faq__page_left_col {
  width: calc(50% - 20px);
}

.faq__page_right_col {
  width: calc(50% - 20px);
}

.faq__page_left_col .qs-container {
  display: flex;
  align-items: center;
  padding: 12px 30px;
  border: 1px solid #707070;
  border-radius: 16px;
}

.faq__page_left_col .qs-container input[type="text"] {
  width: 100%;
  border: none;
  border-radius: 8px;
  color: #292d41;
  font-size: 18px;
  font-weight: 600;
  width: 100%;
  padding: 0 5px 0 15px;
  margin-top: 8px;
  outline: none;
  margin: 0;
}
.faq__page_left_col .qs-container textarea {
  width: 100%;
  border: none;
  border-radius: 8px;
  color: #292d41;
  font-size: 18px;
  font-weight: 600;
  width: 100%;
  outline: none;
  resize: none;
  height: 300px;
}

.faq__page_left_col .qs-container:not(.market):before {
  content: "Q:";
  position: relative;
  top: 2px;
  color: #292d41;
  opacity: 0.5;
  font-size: 21px;
}

.faq__page_left_col .editor-container-main {
  border: 1px solid #707070;
  border-radius: 24px;
  margin-top: 20px;
}

button.add-to-list-button {
  color: #fff;
  font-size: 18px;
  border: 1px solid #00b3e3;
  border-radius: 25px;
  width: 214px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: #00b3e3;
  margin-left: auto;
  margin-top: 26px;
}

.faq__page_faq_list > h4 {
  font-size: 21px;
  font-weight: 700;
  margin-bottom: 20px;
}

.faq-item {
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  padding: 12px 30px;
  border: 1px solid #707070;
  border-radius: 16px;
  margin-bottom: 20px;
}

.faq__page_faq_list .faq-dropdown {
  position: absolute;
  right: 20px;
  top: 11px;
}
.faq__page_faq_list .faq-dropdown button.faq-dropdown-btn {
  border: none;
  padding: 0;
  background: transparent;
  font-size: 22px;
}
.faq__page_faq_list .faq-rectangle {
  color: #292d41;
  opacity: 0.5;
  font-size: 21px;
}

.faq__page_faq_list .faq-header {
  border: none;
  border-radius: 8px;
  color: #292d41;
  font-size: 18px;
  font-weight: 600;
  width: 100%;
  padding: 0 5px 0 15px;
  margin-top: 8px;
  outline: none;
  margin: 0;
}
.save-publish-button-container {
  width: 100%;
  text-align: right;
}

.faq__page_body .save-publish-button-container button.save-publish-button {
  width: 230px;
  height: 50px;
}
.faq-dropdown-content {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 4px 16px #d8e0ed;
  border-radius: 6px;
  display: flex;
  width: 110px;
  flex-wrap: wrap;
  position: absolute;
  right: 10px;
  top: 19px;
  padding: 8px;
  z-index: 3;
}

.faq-dropdown-content button {
  border: none;
  padding: 3px 0;
  text-align: left;
  font-size: 12px;
  width: 100%;
}

.faq__page_right_col .faq-item-ans {
  width: 100%;
  padding-left: 17px;
}

.faq__page_right_col .faq-item-ans * {
  word-wrap: break-word;
}
.faq__page_right_col .faq-text {
  width: 100%;
}
