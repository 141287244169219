/* Updated CSS for the popup */
.popup {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(104, 103, 103, 0.5);
    backdrop-filter: blur(10px); /* Adjust the blur amount as needed */
    /* Semi-transparent black background */
    z-index: 1;
  }
  
  .popup-content {
    
    background-color: white;
    width: 30%;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Add a shadow for the popup */
  }
  
  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    cursor: pointer;
    color: #333; /* Dark gray color for the close button */
  }
  
  .popup-content button {
    background-color: rgb(64, 176, 250); /* Blue background color */
    color: white; /* White text color */
    border: none;
    padding: 10px 20px;
    margin-top: 10px;
    cursor: pointer;
    margin-left: 5%;
    
  }
  
  .popup-content button:hover {
    background-color: #0066cc; /* Darker blue on hover */
  }
  .button-container button{
    margin-bottom: 40px;
    padding: 10px 20px;
    margin-right: 40%;
    background-color: white; 
    color: rgb(64, 176, 250);
    border: none;


  }
  .Discount_code_conainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 16px;
}

.heading__and_addbtn h2 {
    font-size: 21px;
    font-weight: 600;
}

.discount_create_pop_main_wrap {
  background: #1E27396F 0% 0% no-repeat padding-box;
  opacity: 1;
  backdrop-filter: blur(18px);
  -webkit-backdrop-filter: blur(18px);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 22222;
  display: flex;
  align-items: center;
  justify-content: center;
}

.discount_create_pop_content {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 25px #575D6F34;
  border-radius: 30px;
  width: 100%;
  max-width: 570px;
  padding: 50px;
}

.discount_create_pop_group input[type="text"] {
  border: 1px solid #292D4180;
  border-radius: 8px;
  color: #292D41;
  font-size: 12px;
  width: 100%;
  height: 40px;
  padding: 0 5px;
  margin-top: 8px;
}

.discount_create_pop_group select {
  border: 1px solid #292D4180;
  border-radius: 8px;
  color: #292D41;
  font-size: 12px;
  width: 100%;
  height: 40px;
  padding: 0 5px;
  margin-top: 8px;
}

.discount_create_pop_group label {
  color: #292D41;
  font-size: 14px;
  opacity: 0.6;
  padding: 0;
}

.discount_create_pop_group {
  width: calc(50% - 10px);
  margin-bottom: 20px;
}

.discount_create_pop_group.discount_name {
  width: 100%;
}

.discount__field_wrap_body {
  display: flex;
  flex-wrap: wrap;
  column-gap: 20px;
}

.discount_create_pop_group.discount_code {
  width: 100%;
}

.discount_create_pop_content>h2 {
  font-size: 21px;
  font-weight: 600;
  margin-bottom: 25px;
  padding-bottom: 25px;
  border-bottom: 1px solid #292d411f;
}

span.close__discount_create_pop {
  position: absolute;
  right: 30px;
  top: 30px;
  cursor: pointer;
}

.discount__field_wrap_footer {
  border-top: 1px solid #292d411f;
  margin-top: 10px;
  padding-top: 30px;
  display: flex;
  justify-content: flex-end;
  column-gap: 30px;
  width: 100%;
}

.helping_content {
  color: #999B9F;
  font-size: 10px;
  margin-top: 9px;
}

button.discount__field_wrap_footer_cancel {
  color: #292D41;
  cursor: pointer;
  border: none;
  padding: 0;
  background: transparent;
}
 
button.discount__field_wrap_footer_save {
  color: #fff;
  font-size: 18px;
  border: 1px solid #00B3E3;
  border-radius: 25px;
  width: 180px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: #00B3E3;
}

button.discount__field_wrap_footer_save:disabled{
  opacity: 0.5;
  cursor: auto;
}
.helping_content {
  color: #999B9F;
  font-size: 10px;
  margin-top: 9px;
}

button.discount__field_wrap_footer_cancel {
  color: #292D41;
  cursor: pointer;
  border: none;
  padding: 0;
}

button.discount__field_wrap_footer_save {
  color: #fff;
  font-size: 18px;
  border: 1px solid #00B3E3;
  border-radius: 25px;
  width: 180px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: #00B3E3;
}
.close__discount_create_pop_header {
  border-bottom: 1px solid #292d411f;
  margin-bottom: 15px;
  padding-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.close__discount_create_pop_header h2 {
  font-size: 21px;
  font-weight: 600;
  margin: 0;
}

.close__discount_create_pop_header .discount_create_pop_group_select_event {
  display: flex;
  align-items: center;
}

.close__discount_create_pop_header .discount_create_pop_group_select_event label {
  font-size: 16px;
  width: 100%;
}
.discount_create_pop_group input#validFrom {
  border: 1px solid #292D4180;
  border-radius: 8px;
  color: #292D41;
  font-size: 12px;
  width: 100%;
  height: 40px;
  padding: 0 5px;
  margin-top: 8px;
}

.discount_create_pop_group input#validTo {
  border: 1px solid #292D4180;
  border-radius: 8px;
  color: #292D41;
  font-size: 12px;
  width: 100%;
  height: 40px;
  padding: 0 5px;
  margin-top: 8px;
}