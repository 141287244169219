.img__action_wrap img {
    width: 120px;
    height: 120px;
    border-radius: 120px;
}

.user-details__main {
    width: 100%;
}

.user__header_title {
    font-size: 21px;
    font-weight: 700;
    display: flex;
    align-items: center;
    column-gap: 10px;
    margin-bottom: 30px;
    cursor: pointer;
}

.user__header_title img {
    max-width: 20px;
}

.userDetials__info_wrap {
    display: flex;
    justify-content: space-between;
}

.userDetials__info_left {
    display: flex;
    align-items: flex-start;
    column-gap: 47px;
    max-width: 660px;
}

.userDetials__info_left_action {
    display: flex;
    justify-content: center;
    margin-top: 15px;
    color: #FF6C36;
    font-size: 14px;
    font-weight: 600;
    column-gap: 6px;
}
.userDetials__info_left_content_contact_wrap {
    display: flex;
    flex-wrap: wrap;
    column-gap: 34px;
}

.userDetials__info_left_content_contact_item {
    width: 100%;
    max-width: 210px;
    display: inline-flex;
    align-items: center;
    column-gap: 6px;
    margin-bottom: 15px;
}

.userDetials__info_left_content_title {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 18px;
}
.filter-options.member_filter_inner_member {
    justify-content: flex-end;
}
.userDetials__info_ticket_details {
    display: flex;
    flex-wrap: wrap;
    column-gap: 14px;
    align-items: center;
}

.userDetials__info_ticket_detailspurchase {
    background: #00B3E3 0% 0% no-repeat padding-box;
    box-shadow: 0px 5px 13px #00B3E34D;
    border-radius: 20px;
    opacity: 1;
    color: #fff;
    min-height: 5vw;
    display: flex;
    align-items: center;
    padding: 1.042vw;
    column-gap: 15px;
}

.userDetials__info_ticket_detailspurchase_title {
    font-size: 1.094vw;
    font-weight: 500;
    max-width: 6.510vw;
    line-height: 1.3;
}

.userDetials__info_ticket_detailspurchase_count {
    font-size: 40px;
    font-weight: 600;
}
.userDetials__info_ticket_detailsevent {
    background: #FF6C36 0% 0% no-repeat padding-box;
box-shadow: 0px 5px 13px #FF6C364D;
    border-radius: 20px;
    opacity: 1;
    color: #fff;
    min-height: 5vw;
    display: flex;
    align-items: center;
    padding:1.042vw;
    column-gap: 15px;
}

.userDetials__info_ticket_detailsevent_title {
    font-size: 1.094vw;
    font-weight: 500;
    max-width: 6.510vw;
    line-height: 1.3;
}

.userDetials__info_ticket_detailsevent_count {
    font-size: 40px;
    font-weight: 600;
}
.userDetials__info_ticket_detailsamount {
    background: #FFC627 0% 0% no-repeat padding-box;
    box-shadow: 0px 5px 13px #FFC6274D;
        border-radius: 20px;
        opacity: 1;
        color: #fff;
        min-height: 96px;
        display: flex;
        align-items: center;
        padding: 20px;
        column-gap: 15px;
    }
    
    .userDetials__info_ticket_detailsamount_title {
        font-size: 21px;
        font-weight: 500;
        max-width: 138px;
        line-height: 1.3;
    }
    
    .userDetials__info_ticket_detailsamount_count {
        font-size: 40px;
        font-weight: 600;
    }
    .member_info_row {
        cursor: pointer;
    }