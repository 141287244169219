.profile__left_col {
  width: 100%;
  max-width: 130px;
  text-align: center;
}

.profile__left_col img.profile__picture {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.profile__dropzone {
  width: 130px;
  height: 130px;
  border-radius: 130px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
}

.profile__content_wrap_main {
  width: 100%;
  display: flex;
  column-gap: 40px;
}
img.profile-picture {
  cursor: pointer;
}
.profile__form_UploadprofileImage {
  position: absolute;
  bottom: 0;
  background: #0009;
  width: 100%;
  z-index: 2;
  height: 30px;
  text-align: center;
  padding-top: 2px;
}

.profile__header_main h2 {color: #292D41;font-size: 21px;font-weight: 700;margin-bottom: 32px;}

.profile__left_col h5 {
  font-size: 14px;
  margin-top: 10px;
  color: #292D41;
  font-weight: 600;
}

.profile__left_col h5 span {
  display: block;
}

.profile__right_col {
  width: 100%;
  max-width: 830px;
}

.profile__input_row {
  display: flex;
  column-gap: 30px;
}

.profile__checkbox_row {
  display: flex;
  column-gap: 30px;
  flex-wrap: wrap;
}

.profile__input-row {
  display: flex;
  column-gap: 30px;
}


.profile__form_form_field label {
  color: #292D41;
  font-size: 14px;
  opacity: 0.6;
  padding: 0;
  min-width: 240px;
  }

.profile__form_form_field input {
  border: 1px solid #292D4180;
  border-radius: 8px;
  color: #292D41;
  font-size: 12px;
  width: 100%;
  height: 40px;
  padding: 0 5px;
  margin-top: 3px;
}

button.profile__form_form_submit {
  color: #fff;
  font-size: 18px;
  border: 1px solid #00B3E3;
  border-radius: 25px;
  width: 230px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: #00B3E3;
  margin-left: auto;
  margin-top: 40px;;
}
.profile__form_form_field__checkbox_row label span {
  color: #00B3E3;
  font-size: 12px;
  font-weight: bold;
  padding-right: 5px;
}

.profile__form_form_field__checkbox_header {
  width: 100%;
}

.profile__form_form_field__checkbox_header h3 {
  color: #292D41;
  opacity: 0.6;
  font-size: 14px;
  margin-top: 40px;
  font-weight: 700;
}

h3.profile__change_pass_heading {
  color: #292D41;
  font-size: 21px;
  font-weight: 700;
  margin-top: 40px;
  margin-bottom: 15px;
}

.profile__form_form_field__checkbox_row label {
  font-size: 12px;
}