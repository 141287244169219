.event__creation_main_container {
    display: flex;
    column-gap: 52px;
    align-items: flex-start;
}

.event__creation_left_col {
    width: 44.2%;
    display: flex;
    flex-wrap: wrap;
    column-gap: 30px;
    
}

.event__creation_right_right {
    width: 50%;
    display: flex;
    flex-wrap: wrap;
}
.event-edit-screen label>span,
.event-edit-screen .description__field>span {
    display: block;
    margin-bottom: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.event__creation_field_wrap select {
    border: 1px solid #292D4180;
    border-radius: 8px;
    width: 100%;
    height: 40px;
    font-size: 12px;
    text-indent: 5px;
}

.event__creation_field_wrap.event__style_field {
    width: calc(50% - 15px);
}

.event__creation_main_container label,
.event__creation_main_container .description__field  {
    width: 100%;
    color: #292D41;
    opacity: 0.6;
    font-size: 14px;
    padding: 0;
}

.event__creation_field_wrap {
    margin-bottom: 30px;
}

.event__creation_field_wrap.canvas__field {
    width: calc(50% - 15px);
}

.event__creation_field_wrap  input {
    border: 1px solid #292D4180;
    border-radius: 8px;
    width: 100%;
    height: 40px;
    font-size: 12px;
    text-indent: 5px;
}

.event__creation_field_wrap.event__name_field {
    width: calc(50% - 15px);
}

.event__creation_field_wrap.event__type_field ,.event__creation_field_wrap.ticket__style_field ,.event__creation_field_wrap.date__from_field ,.event__creation_field_wrap.date__to_field ,.event__creation_field_wrap.start_time_field ,.event__creation_field_wrap.end__time_field ,.event__creation_field_wrap.checkins__start_field ,.event__creation_field_wrap.checkins__end_field ,.event__creation_field_wrap.vat_field ,.event__creation_field_wrap.currency__field,.event__creation_field_wrap.dis_start_date_field,.event__creation_field_wrap.dis_end_date_field,.event__creation_field_wrap.show_add__field,.event__creation_field_wrap.in_ticket_field,.event__creation_field_wrap.max_ticket_field,.event__creation_field_wrap.max_checkins_field,.event__creation_field_wrap.ticket_avail_from_field,.event__creation_field_wrap.ticket_avail_to_field,.event__creation_field_wrap.available_time_field,.event__creation_field_wrap.max_time_trnsfer_field {
    width: calc(25% - 22.5px);
    min-width: 96px;
}

.event__creation_field_wrap.select_discount_field {
    width: calc(50% - 15px);
    position: relative;
    z-index: 5;
    padding-bottom: 40px;
}

.event__creation_field_wrap input[type="date"] {
    text-indent: 4px;
}



.event__creation_field_wrap.select_speaker_field {
    width: calc(50% - 15px);
}

.event__creation_field_wrap.vanue__field {
    width: 100%;
}

.select_discount_field_drop {
    border: none !important;
}


.save__bt_publish.disabled{
    pointer-events: none !important;
}

.event__creation_field_wrap.show_add__field input[type="checkbox"] {
    width: 18px;
    height: auto;
    position: relative;
    top: -1px;
    margin-right: 6px;
}

.event__creation_field_wrap.show_add__field label {
    display: flex;
    align-items: center;
}

.event-edit-screen h2 {
    font-size: 21px;
    color: #292D41;
    font-weight: 600;
    margin-bottom: 0;
}
.event__creation_field_wrap.max_time_trnsfer_field:after {
    bottom: 5px;
}
.eventPage__header {
    font-size: 21px;
    color: #292D41;
    font-weight: 600;
    border-bottom: 1px solid #d7d7d7;
    margin-bottom: 33px;
    padding-bottom: 23px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.eventPage__header label {
    display: flex;
    align-items: center;
    font-size: 15px;
    min-width: 360px;
}

.eventPage__header label span {
    margin-bottom: 0;
    margin-right: 9px;
    min-width: 110px;
}

.eventPage__header label .css-b62m3t-container {
    width: 100%;
}
.tickets-container h2 {
    display: flex;
    align-items: center;
}

.tickets-container h2 span.addnew__newsUser_btn {
    color: #00B3E3;
    font-weight: 100;
    border: 1px solid #00B3E3;
    border-radius: 25px;
    height: 40px;
    display: inline-flex;
    align-items: center;
    padding: 0 20px;
    margin-left: 30px;
    font-size: 18px;
    line-height: 1;
    cursor: pointer;
}
.event__creation_field_wrap.hidden_field {
    position: relative;
}

/* .event__creation_field_wrap.hidden_field:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 40px;
    background: #626262c7; 
    z-index: 3;
    bottom: 0;
    left: 0;
    border-radius: 8px;
} */
.event__creation_field_wrap.description__field {
    min-height: 200px;
    width: 100%;
}

.event__creation_field_wrap.description__field .quill {
    min-height: 200px;
    border: 1px solid #292D4180;
    border-radius: 8px;
}

.event__creation_field_wrap.description__field .quill .ql-editor.ql-blank {
    min-height: 100% !important;
}
.dropzone .event__creation__sponserlogoupload_file {
    border: 1px dashed #00B3E3;
    width: 70px;
    height: 70px;
    position: relative;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    overflow: hidden;
}

.event-edit-screen input[type="file"] {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 222;
    cursor: pointer;
}

.event__creation__eventphoto_upload_file {
    border: 1px dashed #00B3E3;
    width: 70px;
    height: 70px;
    position: relative;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    overflow: hidden;
    position: relative;
}

.image-upload-container-file {
    border: 1px dashed #00B3E3;
    width: 70px;
    height: 70px;
    position: relative;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    overflow: hidden;
}

.event__creation__eventphoto_upload {
    width: 25%;
    margin-bottom: 20px;
}

.image-upload-container {
    width: 75%;
    margin-bottom: 20px;
}

.event__creation__sponserlogo_wrap {
    width: 100%;
    margin-bottom: 20px;
}

.event__creation__eventphoto_upload_file:after {
    content: '+';
    color: #00B3E3;
    border: 1px solid #00B3E3;
    width: 20px;
    height: 20px;
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    border-radius: 25px;
}

.image-upload-container-file:after {
    content: '+';
    color: #00B3E3;
    border: 1px solid #00B3E3;
    width: 20px;
    height: 20px;
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    border-radius: 25px;
}

.event__creation__sponserlogoupload_file:after {
    content: '+';
    color: #00B3E3;
    border: 1px solid #00B3E3;
    width: 20px;
    height: 20px;
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    border-radius: 25px;
}


.event__creation_right_right label,
.event__creation_right_right .description__field {
    margin-bottom: 15px;
}
.uploaded-image {
    width: 70px;
    height: 70px;
    border: 1px solid #292D41;
    border-radius: 6px;
    overflow: hidden;
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
}
.image-preview {
    display: inline-flex;
    width: fit-content;
}

.dropzone {
    display: flex;
    width: fit-content;
}

.image-upload-dropzone{
    display: flex;
    width: fit-content;
}

.uploaded-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.uploaded-image button {
    color: #fff;
    border: 1px solid #fff;
    width: 20px;
    height: 20px;
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    border-radius: 25px;
    z-index: 9;
    background: transparent;
    transform: rotate(45deg);
}
.footer__event_creation_form {
    display: flex;
    justify-content: space-between;
    padding-top: 40px;
    margin-top: 30px;
    border-top: 1px solid #e7e7e7;
}

button.save__bt_draft {
    color: #00B3E3;
    font-weight: 100;
    border: 1px solid #00B3E3;
    border-radius: 25px;
    height: 40px;
    display: inline-flex;
    align-items: center;
    padding: 0 20px;
    margin-left: 30px;
    font-size: 18px;
    line-height: 1;
}

button.save__bt_publish {
    color: #fff;
    font-size: 18px;
    border: 1px solid #00B3E3;
    border-radius: 25px;
    width: 180px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: #00B3E3;
}

button.save__bt_cancle {
    color: #292D41;
    cursor: pointer;
    border: none;
    padding: 0;
    background-color: transparent;
}

.event_btn_right {
    display: flex;
    align-items: center;
    column-gap: 30px;
}
.event__creation_field_wrap.select_speaker_field .select_speaker_field_drop.form-select.css-b62m3t-container {
    padding: 0;
    border: 1px solid #292D4180;
--bs-form-select-bg-img: none !important;
border-radius: 10px;
}
/* .event__creation_field_wrap.select_speaker_field *, .event__creation_field_wrap.select_discount_field *{background: transparent !important;} */
.event__creation_field_wrap.select_discount_field .select_discount_field_drop.form-select.css-b62m3t-container {
    padding: 0;
    border: 1px solid #292D4180 !important;
    /* background: transparent !important; */
--bs-form-select-bg-img: none !important;
border-radius: 10px;
}

.css-1fdsijx-ValueContainer {
    border: none !important;
}

.css-13cymwt-control {
    border: none;
    font-size: 12px;
    min-height: 30px !important;
    background: transparent !important;
}

.css-1xc3v61-indicatorContainer {
    color: #717171 !important;
}

span.css-1u9des2-indicatorSeparator {
    display: none;
}

.css-qbdosj-Input input{
    height: 25px;
}