.filter__option_col_wrap {
    display: flex;
    column-gap: 30px;
    justify-content: flex-end;
    width: 100%;
}

.max-w-442{width: 442px;}

.finanaceTrackTitle img {
    width: 20px;
    cursor: pointer;
}

.finanaceTrackTitle {
    display: flex;
    align-items: center;
    column-gap: 10px;
    font-size: 21px;
    font-weight: 600;
}

.filter-options.filter-options-finance {
    align-items: flex-end;
    padding-bottom: 0 !important;
}

.userDetailsWrap {
    display: flex;
    align-items: center;
    column-gap: 20px;
    /* margin-bottom: 30px; */
}

.userNameHeading {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 3px;
}

.userEmailrow {
    font-size: 14px;
    margin-bottom: 10px;
}

.userDetails {
    min-width: 350px;
}

.userPhonerow {
    font-size: 14px;
    margin: 0;
}

.userRegisterFromrow {
    font-size: 14px;
    margin: 0;
}
.fiinanaceRowImg img {
    width: 38px;
}

.fininaceRow {
    cursor: pointer;
}