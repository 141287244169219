.table-container {
    width: 100%;
    overflow-x: auto;
    margin: 0 auto;
    margin-bottom: 80px;
  }
  
  .data-table {
    border-collapse: collapse;
    width: 100%;
    border: 1px solid #ddd;
  }
  
  .data-table th, .data-table td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  .data-table th {
    background-color: #f2f2f2;
  }
  .tableheading__viewall {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    column-gap: 10px;
    font-size: 12px;
}

.tableheading__viewall img {
    max-width: 11px;
}