.headerInner__main_wrap {
  background-color: white;
}

.headerInner__main_wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px;
  border-bottom: 1px solid #ccc;
  margin: 0 44px;
  background: transparent;
}
.headerInner_main_container {
  margin-left: 290px;
  margin-top: 30px;
  max-width: 83%;
  background: #fff;
  padding-top: 20px;
  border-radius: 30px 30px 0 0;
}
.left {
  display: flex;
  align-items: center;
}

.search-icon {
  font-size: 20px;
  margin-right: 10px;
}

.search-input {
  border: none;
  border-radius: 4px;
  padding: 8px;
  outline: none;
  width: 100%;
}
.headerInner__main_wrap i.fa-solid.fa-magnifying-glass {
  color: #444444;
  font-size: 20px;
}
.right {
  display: flex;
  align-items: center;
}
.headerInner__main_wrap .left {
  width: 50%;
}
.right > * {
  margin-left: 30px;
}

.icon {
  font-size: 24px;
  margin: 0 10px;
  cursor: pointer;
}

.live-feed {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1px 23px;
  border-radius: 30px;
  border: 1px solid rgb(61, 187, 237);
  color: rgb(61, 187, 237);
  font-size: 18px;
  font-weight: bold;
}
.cardnav-wrapper {
  margin-top: -90px;
}
.live-feed > span {
  font-weight: 200;
}

.live-feed > * {
  padding: 7px;
}

.live-feed:hover {
  background-color: rgb(61, 187, 237);
  color: white;
  cursor: pointer;
}

.notification {
  position: relative;
  color: #ff6c36;
  font-size: 24px;
}
.live-feed i.fa-solid.fa-play {
  padding: 0;
  font-size: 22px;
}
.notification:after {
  content: "";
  display: block;
  position: absolute;
  width: 8px;
  height: 8px;
  background: #ff6c36;
  top: 0;
  right: 0;
  border-radius: 50%;
}

.profile-picture {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}
body {
  background-color: transparent !important;
}

/* Add these styles to your CSS file */
.drawer__cart_box {
  position: absolute;
  top: 110px;
  right: 140px;
  width: 597px;
  background-color: #fff;
  border-radius: 5px;
  z-index: 9999;
  padding: 48px 30px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 25px #575d6f34;
}
.drawer__cart_box .table-wrapper {
  max-height: 300px;
  overflow-y: auto;
}
.drawer__cart_box_overlay {
  background: #0000000e;
  content: "";
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9;
  display: none;
  cursor: pointer;
}

.drawer__cart_box table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 10px;
}

.drawer__cart_box th,
.drawer__cart_box td {
  padding: 8px;
  text-align: left;
}

.drawer__cart_box th {
  background-color: #f5f5f5;
  font-weight: bold;
}

.drawer__cart_box .totals {
  margin-top: 10px;
  font-weight: bold;
}

.drawer__cart_box button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 70px;
}

.drawer__cart_box button:hover {
  background-color: #0056b3;
}

i.fa.fa-times-circle-o {
  color: #ff6c36;
}

.drawer__cart_box tbody tr td {
  padding: 20px 0;
  border-top: 1px solid #e9e9e9;
}

.drawer__cart_box th {
  padding-bottom: 20px;
}

.cart__drawer_subTotal {
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  font-weight: 100;
  margin-bottom: 22px;
}

.cart__drawer_discount {
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  font-weight: 100;
  margin-bottom: 22px;
  color: #ff3b3b;
}

.cart__drawer_vat {
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  font-weight: 100;
  margin-bottom: 22px;
}

.cart__drawer_totals {
  display: flex;
  justify-content: space-between;
  font-size: 24px;
  font-weight: 600;
  color: #00b3e3;
  border-top: 1px solid #e9e9e9;
  padding-top: 15px;
}

.cart__drawer_subTotal strong {
  color: #999b9f;
}

.cart__drawer_discount strong {
  color: #999b9f;
}

.cart__drawer_vat strong {
  color: #999b9f;
}

.cart__drawer_totals strong {
  font-size: 18px;
  font-weight: 600;
  color: #292d41;
}

.cart__drawer_totals_wrap {
  padding: 20px;
  border-top: 1px solid #e9e9e9;
  border-bottom: 1px solid #e9e9e9;
  margin-bottom: 20px;
}
.cart__drawer_btn_box {
  display: flex;
  justify-content: flex-end;
}

button.cart__drawer_buyTicket,
button.cart__drawer_buyTicket:hover {
  margin: 0;
  color: #fff;
  font-size: 18px;
  border: 1px solid #00b3e3;
  border-radius: 25px;
  width: 180px;
  height: 43px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: #00b3e3;
  font-weight: 600;
  margin-left: 10px;
}

button.cart__drawer_reserve,
button.cart__drawer_reserve:hover {
  color: #00b3e3;
  font-weight: 600;
  border: 1px solid #00b3e3;
  border-radius: 25px;
  height: 43px;
  display: inline-flex;
  align-items: center;
  padding: 0 20px;
  margin-left: 30px;
  font-size: 18px;
  line-height: 1;
  background: transparent;
  min-width: 180px;
  justify-content: center;
}

img.cartIcon_btn {
  cursor: pointer;
}

.disabledButton {
  pointer-events: none;
  opacity: 0.65;
}

.buyerDetailsEmpty {
  color: red;
  text-align: end;
  margin-top: 10px;
}
.cart__drawer_clear {
  background-color: transparent !important;
  color: #00b3e3 !important;
  font-weight: 600;
  cursor: pointer !important;
}
.drawer__cart_box button.cart__drawer_clear {
  margin-left: 0px !important;
}
