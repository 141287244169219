ul.card__newsUpdated_wrap {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    list-style: none;
    column-gap: 30px;
    margin-top: 7px;
}

ul.card__newsUpdated_wrap li {
    width: 100%;
    max-width: 352px;
    padding: 20px;
    box-shadow: 6px 20px 25px #363E9326;
    border-radius: 20px;
    margin-bottom: 30px;
}

ul.card__newsUpdated_wrap li>img {
    width: 100%;
    border-radius: 20px;
}

.page__container_wrapper.policy__page h2 {
    width: 100%;
}

.content__box_category_name {
    font-size: 14px;
    color: #00B3E3;
    font-weight: 600;
    margin-top: 20px;
}

.content__box_category_title {
    font-size: 16px;
    color: #292D41;
    line-height: 1.5;
    margin-top: 5px;
}

.content__box_category_description {
    font-size: 12px;
    color: #999B9F;
    margin-top: 10px;
    padding-bottom: 15px;
    margin-bottom: 17px;
    border-bottom: 1px solid #e6e6e6;
    -webkit-line-clamp: 5;
    min-height: 200px;
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    
}

.content__box_footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.content__box_time_actionbtns {
    display: flex;
    align-items: flex-end;
}

.content__box_auther {
    font-size: 13px;
    color: #999B9F;
}

.content__box_time_col {
    font-size: 13px;
    color: #999B9F;
}

.content__box_actionbtns button {
    padding: 0;
    border: none;
    background: none;
    margin-left: 20px;
}
.page__container_wrapper.news__events {
    padding-bottom: 0;
}
.page__container_wrapper.news__events h2 {
    font-size: 21px;
    font-weight: 600;
    margin-bottom: 0 !important;
   
}
.newsandupdate__list_main {
    display: flex;
    width: 100%;
}
.newslist__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 16px;
}

.newsfilter__main_wrap {
    display: flex;
    column-gap: 30px;
}

.newsfilter__filter_fields label {
    padding: 0;
    width: 100%;
    font-size: 12px;
    color: #292D41;
    font-weight: 600;
    margin-bottom: 7px;
}

.newsfilter__filter_fields  select {
    border: 1px solid #292D4180;
    border-radius: 8px;
    height: 30px;
    color: #292D41;
    font-size: 12px;
    width: 100%;
    min-width: 125px;
    padding: 0 6px;
}

.newsfilter__filter_fields input#datePicker {
    border: 1px solid #292D4180;
    border-radius: 8px;
    height: 30px;
    color: #292D41;
    font-size: 12px;
    width: 100%;
    min-width: 168px;
    padding: 0 6px;
}
span.addnew__newsUpdate_btn {
    font-size: 14px;
    color: #00B3E3;
    font-weight: 100;
    display: inline-flex;
    align-items: center;
    line-height: 1.2;
    position: relative;
    top: 3px;
    margin-left: 30px;
    cursor: pointer;
}

span.addnew__newsUpdate_btn img {
    width: 18px;
    height: 18px;
    margin-right: 10px;
}
.addnewsupdates__body_wrap {
    display: flex;
    width: 100%;
    margin-top: 40px;
    column-gap: 30px;
    align-items: flex-start;
}

.addnewsupdates__left__fields_box {width: 100%;max-width: 316px;}

.addnewsupdates__right_description_box {
    width: 100%;
    max-width: 1166px;
}

.addnewsupdates__right_description_box .quill {
    width: 100%;
    border: 1px solid #292D4180;
    border-radius: 8px;
}

.addnewsupdates__main_wrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 30px;
}

.addnewsupdates__image_upload .dropzone{
    width: 70px;
    height: 70px;
    position: relative;
    border: 1px solid #292D41;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    overflow: hidden;
}

.addnewsupdates__image_upload .dropzone img.uploaded-image {
    width: 100%;
    object-fit: cover;
    width: 100%;
    height: 100%;
    margin: 0;
}

.addnewsupdates__image_upload .dropzone button {
    position: absolute;
    top: 18.5px;
    left: 23.5px;
    background: transparent;
    color: #fff;
    border: 1px solid #fff;
    width: 23px;
    height: 23px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    border-radius: 30px;
    transform: rotate(45deg)
}

.addnewsupdates__left__fields_box_col p.empty__image {
    font-size: 8px;
    color: #00B3E3;
    margin: 0;
}


.addnewsupdates__left__fields_box_col .dropzone.noImage {
    border: 1px dashed #00B3E3;
}



.addnewsupdates__left__fields_box_col label {
    font-size: 14px;
    color: rgb(41 45 65 / 60%);
    font-weight: 600;
    width: 100%;
    padding: 0;
    margin-bottom: 8px;
}

.addnewsupdates__left__fields_box input[type="text"] {
    border: 1px solid rgb(41 45 65 / 25%);
    border-radius: 8px;
    width: 100%;
    height: 40px;
    margin-bottom: 20px;
}

.addnewsupdates__right_description_box label {
    font-size: 14px;
    color: rgb(41 45 65 / 60%);
    font-weight: 600;
    width: 100%;
    padding: 0;
    margin-bottom: 8px;
}

.addnewsupdates__right_description_box .ql-editor.ql-blank {
    min-height: 269px;
}

.addnewsupdates__footer_wrap {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-top: 90px;
}

.addnewsupdates__footer_savDraft_btn {
    color: #00B3E3;
    font-size: 18px;
    border: 1px solid #00B3E3;
    border-radius: 25px;
    width: 154px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.addnewsupdates__footer_cancel_publish_box {
    display: flex;
    align-items: center;
    column-gap: 30px;
}

.addnewsupdates__footer_cancel_btn {
    color: #292D41;
    cursor: pointer;
}

.addnewsupdates__footer_publish_btn {
    color: #fff;
    font-size: 18px;
    border: 1px solid #00B3E3;
    border-radius: 25px;
    width: 180px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: #00B3E3;
}
.addnewsupdates__left__fields_box_col p.empty__image span {
    color: #00B3E3;
    border: 1px solid #00B3E3;
    width: 23px;
    height: 23px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    border-radius: 30px;
    margin-bottom: 4px;
}