.leftSidebar__logo_navigation {
    position: fixed;
    top: 0;
    width: 30%;
    max-width: 220px;
    background: #fff;
    height: 100vh;
    overflow: auto;
    padding-top: 13px;
    z-index: 9999;
}

.header__logo_main {
    padding-left: 30px;
    margin-bottom: 25px;
}

.header__logo_main img {
    width: 100%;
    max-width: 88px;
}

.leftMenu__main_wrap a {
    padding: 12px 0 12px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 300;
    border-radius: 0 10px 10px 0;
    cursor: pointer;
    margin-bottom: 7px;
}

.leftMenu__main_wrap a img {
    margin-right: 8px;
}
.leftMenu__main_wrap a:hover {
    background: #00B3E3;
    color: #fff;
    border-radius: 0 10px 10px 0;
}

.leftMenu__main_wrap a:hover img {
    filter: invert(100%) sepia(93%) saturate(2%) hue-rotate(337deg) brightness(112%) contrast(100%);
}

.leftMenu__main_wrap a span {
    width: 80%;
}

ul.paginationBot {
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: center;
    margin-top: 16px;
}

ul.paginationBot li.previous a {
    text-decoration: none;
    color: #292D41;
    font-family: cursive;
    font-size: 25px;
    line-height: 0.6;
}

.leftMenu__main_wrap .selected {
    background: #00B3E3 !important;
    color: #fff !important;
    border-radius: 0 10px 10px 0 !important;
    box-shadow: 0px 4px 8px #00B3E34D;
    border: 1px solid #00B3E3;
}

.leftMenu__main_wrap .selected img {
    filter: invert(100%) sepia(93%) saturate(2%) hue-rotate(337deg) brightness(112%) contrast(100%);
}

ul.paginationBot li.selected {
    background: #00B3E3;
    color: #fff;
    border-radius: 4px;
}

ul.paginationBot li.next a {
    text-decoration: none;
    color: #292D41;
    font-family: cursive;
    font-size: 25px;
      line-height: 0.6;
}

ul.paginationBot  li {
    color: #292D41;
    font-size: 14px;
    padding: 0 3px;
    min-width: 19px;
    text-align: center;
    display: inline-flex;
    justify-content: center;
}
.leftSidebar__logo_navigation::-webkit-scrollbar {
    width: 2px;
  }
  .leftSidebar__logo_navigation::-webkit-scrollbar-track {
    background: #e2e2e2;
  }
  .leftSidebar__logo_navigation::-webkit-scrollbar-thumb {
    background: #9E9E9E;
  }
  .leftSidebar__logo_navigation::-webkit-scrollbar-thumb:hover {
    background: #555;
  }