/* styles.css */

.button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    column-gap: 15px;
}

/* Base styles for both button classes */
.button-container .button {
    position: relative;
    display: flex;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 20px;
    cursor: auto;
    color: #fff;
    border: none;
    margin: 0;
    width: 24%;
    min-height: 130px;
    align-items: center;
    flex-wrap: wrap;
}
h2.header__main_overview {
    font-size: 21px;
    font-weight: 600;
    margin-bottom: 40px;
}
span.main__box_icon span {
    width: 100%;
}

span.main__box_icon {
    display: flex;
    flex-wrap: wrap;
    font-size: 21px;
    font-weight: 100;
}

span.main__box_icon span.count {
    text-align: left;
}
.button-text {
    font-size: 14px;
    margin-top: 5px; /* Add some spacing between the text and button */
}
button.button.ticket-sales {
    background: #00B3E3;
}

button.button.footfall {
    background: #FF6C36;
}

button.button.total-sales {
    background: #FFC627;
}

button.button.app-members {
    background: #FF9BB1;
}
.button-container .button .count {
    font-size: 2.604vw;
    font-weight: bold;
    line-height: 1;
}
.bottom-right-image {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 40px; /* Adjust the width as needed */
    height: auto; /* Maintain aspect ratio */
}

.appbody__table_wrap table.data-table {
    border: none;
}

.appbody__table_wrap table.data-table th {
    font-size: 12px;
    border: none;
    background: transparent;
    padding: 15px;
}

.appbody__table_wrap table.data-table thead {
    border: none;
    border-radius: 26px;
    background-color: #f2f2f2;
}

.appbody__table_wrap table.data-table thead tr {
    border: none;
}

.appbody__table_wrap .table-container>h2 {
    font-size: 21px;
    font-weight: 600;
    margin-bottom: 25px;
}

.appbody__table_wrap table.data-table td {
    padding: 20px 15px;
}
html {
    background: #F4F7FC;
}
.button-container.list_dasboard_buttons button {
    position: relative;
}

.button-container.list_dasboard_buttons button .icon__image_buttons {
    position: absolute;
    bottom: 0;
    right: 0;
}

.button-container .button .count {
    max-width: 150px;
}

.button-container.list_dasboard_buttons button img.icon__image_DottedLine {
    position: absolute;
    top: 20px;
    right: 20px;
}
.card__dashboard_header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.card__dashboard_header select {
    border: 1px solid #292D4180;
    border-radius: 8px;
    height: 30px;
    color: #292D41;
    font-size: 12px;
    width: 100%;
    min-width: 194px;
    padding: 0 6px;
    max-width: 140px;
}