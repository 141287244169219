.speaker__page_body {
    display: flex;
    flex-wrap: wrap;
    column-gap: 25px;
    margin-top: 30px;
}

.speaker-box {
    width: 100%;
    max-width: 355px;
    box-shadow: 6px 20px 25px #363E9326;
    border-radius: 20px;
    padding:20px;
    text-align: center;
    margin-bottom: 80px;
    position: relative;
}

.speaker-box img.speaker-image {
    width: 100%;
    max-width: 130px;
    height: 130px;
    object-fit: cover;
    border-radius: 120px;
    border: 10px solid #F9F9FC;
    margin: 0 auto;
    margin-top: -65px;
}

.speaker__page_header h2 {
    font-size: 21px;
    font-weight: 600;
    margin-bottom: 0 !important;
}

.speaker__page_header {
    display: flex;
    align-items: center;
    margin-bottom: 70px;
}

h3.speaker_box_speakerRole {
    font-size: 14px;
    color: #00B3E3;
    margin: 0;
    margin-top: 10px;
}

p.speaker_box_speakerName {
    margin: 0;
    font-size: 26px;
    color: #292D41;
    font-weight: 600;
    line-height: 1;
    margin-bottom: 5px;
}

p.speaker_box_speakerDesignation {
    margin: 0;
    font-size: 14px;
    color: #FF6C36;
}

p.speaker_box_speakerDescripttion {
    font-size: 12px;
    color: #999B9F;
    margin-top: 10px;
}
.speaker_box_social_icon_box {
    display: flex;
    justify-content: center;
    column-gap: 15px;
}
.speaker_box_edit_delete {
    position: absolute;
    right: 20px;
}

.speaker_box_edit_delete>img {
    cursor: pointer;
}

.speaker_box_edit_delete_menu {
    position: absolute;
    right: 10px;
    top: 0;
    box-shadow: 1px 6px 12px #363E9326;
    border-radius: 5px;
    padding: 15px 10px 0px 10px;
    text-align: left;
}

.speaker_box_edit_delete_menu * {
    line-height: 1;
    font-size: 12px;
    margin-bottom: 9px;
    cursor: pointer;    
}

.addSpeaker_form_leftCol_field {
    display: inline-flex;
    width: 47%;
    flex-wrap: wrap;
    column-gap: 22px;
}

.addSpeaker_form_leftCol_field input[type="text"] {
    width: 49%;
}

.addSpeaker_form_leftCol_field  select {
    width: 49%;
}

.addSpeaker_form_right_field {
    display: inline-flex;
    width: 53%;
    flex-wrap: wrap;
}

.addSpeaker_form_fields {
    display: flex;
    align-items: flex-start;
    margin-top: 40px;
    column-gap: 58px;
    border-bottom: 1px solid #292d4126;
    padding-bottom: 30px;
    margin-bottom: 40px;
}
.addSpeaker__block_header h2 {
    font-size: 21px;
    font-weight: 600;
    margin-bottom: 30px;
}

label.addSpeaker_form_coverImage_label {
    padding: 0;
    border: 1px dashed #B5B4B4;
    border-radius: 10px;
    width: 100%;
    height: 200px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
}

label.addSpeaker_form_coverImage_label input[type="file"] {
    opacity: 0;
    position: absolute;
}

.addSpeaker_form_UploadcoverImage {
    position: absolute;
    right: 28px;
    bottom: 28px;
    font-size: 14px;
    display: flex;
    align-items: center;
    line-height: 1;
}

.addSpeaker_form_UploadcoverImage>img {margin-right: 10px;}

label.addSpeaker_form_coverImage_label>img {
    width: 100%;
    object-fit: cover;
    object-position: bottom;
}

.addSpeaker_form_profileImage {
    max-width: 130px;
    margin: 0 auto;
    position: relative;
    margin-top: -110px;
}

.addSpeaker_form_profileImage input[type="file"] {
    opacity: 0;
    position: absolute;
}

.addSpeaker_form_profileImage_heading {
    color: #292D41;
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    margin-top: 0;
}

label.addSpeaker_form_profileImage_label>img {
    width: 100%;
    object-fit: cover;
    height: 100%;
}

label.addSpeaker_form_profileImage_label {
    padding: 0;
    width: 130px;
    height: 130px;
    border-radius: 130px;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    border: 1px solid #292D4180;
}
.addSpeaker_form_profileImage_wrap,img.list__image_url {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.addSpeaker_form_UploadprofileImage {
    position: absolute;
    bottom: 0;
    background: #0009;
    width: 100%;
    z-index: 2;
    height: 30px;
    text-align: center;
    padding-top: 2px;
}

.addSpeaker_form_form_field label {
    color: #292D41;
    font-size: 14px;
    opacity: 0.6;
    padding: 0;
}

.addSpeaker_form_form_field input[type="text"] {
    border: 1px solid #292D4180;
    border-radius: 8px;
    color: #292D41;
    font-size: 12px;
    width: 100%;
    height: 40px;
    padding: 0 5px;
    margin-top: 8px;
}

.addSpeaker_form_form_field select {
        border: 1px solid #292D4180;
    border-radius: 8px;
    color: #292D41;
    font-size: 12px;
    width: 100%;
    height: 40px;
    padding: 0 5px;
    margin-top: 8px;

}

.addSpeaker_form_form_field {
    width: calc(50% - 11px);
    margin-bottom: 30px;
}

.addSpeaker_form_form_field_social {
    width: calc(50% - 11px);
    margin-bottom: 30px;
}

.addSpeaker_form_form_field_social label {
    color: #292D41;
    font-size: 14px;
    opacity: 0.6;
    padding: 0;
}

.addSpeaker_form_form_field_social_field_wrap {
    border: 1px solid #292D4180;
    border-radius: 8px;
    padding: 5px;
}

.addSpeaker_form_form_field_social_field_wrap  input[type="text"] {
    border: none;
    outline: none;
}

.addSpeaker_form_form_field_social_field_wrap img {
    width: 30px;
    margin-right: 10px;
}
.addSpeaker_form_right_field .quill {
    width: 100%;
    border: 1px solid #292D4180;
    border-radius: 8px;
    min-height: 340px;
}

.addSpeaker_form_right_field .quill .ql-editor.ql-blank {
    min-height: 100%;
}

.addSpeaker_form_right_field h3 {
    color: #292D41;
    font-size: 14px;
    opacity: 0.6;
    padding: 0;
}
.addSpeaker_form_footer {
    display: flex;
    justify-content: flex-end;
    column-gap: 30px;
}

button.addSpeaker_form_footer_reset {
    color: #292D41;
    cursor: pointer;
    border: none;
    padding: 0;
    background-color: transparent;
}

button.addSpeaker_form_footer_submit {
    color: #fff;
    font-size: 18px;
    border: 1px solid #00B3E3;
    border-radius: 25px;
    width: 180px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: #00B3E3;
}

.userCreation__back_button {
    font-size: 21px;
    font-weight: 500;
    margin-bottom: 40px;
}

.userCreation__back_button i.fa.fa-long-arrow-left{
    color: #00B3E3;
    margin-right: 10px;
}



img.userDetails_edit_profile_picture {
    width: 108px;
    height: 108px;
    border-radius: 108px;
}

.userDetails_edit_action_btn {
    max-width: 108px;
    text-align: center;
    margin-top: 10px;
}

.userDetails_edit_action_btn button.userDetails_action_dlt_btn {
    background: none;
    border: none;
    padding: 0;
    color: #FF6C36;
    text-decoration: underline;
    font-weight: 500;
}

.userDetails_edit_action_btn button.userDetails_action_edit_btn {
    background: none;
    border: none;
    padding: 0;
    color: #FF6C36;
    text-decoration: underline;
    font-weight: 500;
}

span.userDetails_action_sep {
    color: #FF6C36;
}

.userDetails_edit_main_container {
    display: flex;
    align-items: flex-start;
    margin-bottom: 41px;
}

.userInfo_details_box {
    margin-left: 20px;
}

.userInfo_details_box h3 {
    font-size: 18px;
    font-weight: 500;
    margin: 0;
}

.userInfo_details_box p {
    margin-bottom: 0;
}

.userInfo__box_roles{
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
    margin-left: 115px;
}

.userInfo__box_roles h3 {
    width: 100%;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 15px;
}
.userInfo__box_roles img {
    margin-right: 7px;
}

.userInfo__button_container {
    display: flex;
    column-gap: 25px;
}

.userInfo__ticket_solds {
    width: 100%;
    max-width: 320px;
    background: #00B3E3;
    color: #fff;
    box-shadow: 0px 5px 13px #00B3E34D;
    border-radius: 20px;
    min-height: 123px;
    padding: 17px;
}

.userInfo__ticket_aount_raised {
    width: 100%;
    max-width: 320px;
    background: #8ED8A8;
    color: #fff;
    box-shadow: 0px 5px 13px #8ED8A84D;
    border-radius: 20px;
    min-height: 123px;
    padding: 17px;
}

.userInfo__ticket_total_ticket_reserved {
    width: 100%;
    max-width: 320px;
    background: #999B9F;
    color: #fff;
    box-shadow: 0px 5px 13px #999B9F4D;
    border-radius: 20px;
    min-height: 123px;
    padding: 17px;
}

.userInfo__ticket_total_amount_receivable {
    width: 100%;
    max-width: 320px;
    background: #FF6C36;
    color: #fff;
    box-shadow: 0px 5px 13px #FF6C364D;
    border-radius: 20px;
    min-height: 123px;
    padding: 17px;
}

.userInfo__ticket_current_balance_amount {
    width: 100%;
    max-width: 320px;
    background: #FF9BB1;
    color: #fff;
    box-shadow: 0px 5px 13px #FF9BB14D;
    border-radius: 20px;
    min-height: 123px;
    padding: 17px;
}

.userInfo__ticket_solds span.main__box_icon {
    font-size: 21px;
    font-weight: 500;
}

.userInfo__ticket_solds span.count {
    font-size: 40px;
    font-weight: 500;
}

.userInfo__ticket_aount_raised span.main__box_icon {
    font-size: 21px;
    font-weight: 500;
}

.userInfo__ticket_aount_raised span.count {
    font-size: 40px;
    font-weight: 500;
}

.userInfo__ticket_total_ticket_reserved span.main__box_icon {
    font-size: 21px;
    font-weight: 500;
}

.userInfo__ticket_total_ticket_reserved span.count {
    font-size: 40px;
    font-weight: 500;
}

.userInfo__ticket_total_amount_receivable span.main__box_icon {
    font-size: 21px;
    font-weight: 500;
}

.userInfo__ticket_total_amount_receivable span.count {
    font-size: 40px;
    font-weight: 500;
}

.userInfo__ticket_current_balance_amount span.main__box_icon {
    font-size: 21px;
    font-weight: 500;
}

.userInfo__ticket_current_balance_amount span.count {
    font-size: 40px;
    font-weight: 500;
}
.userCreation__main_filteroption_wrap {
    display: flex;
    justify-content: space-between;
}

.userCreation__main_filteroption_fields {
    display: flex;
    column-gap: 25px;
}

.userCreation__main_edit_filters {
    margin: 65px 0 35px 0;
}

.userCreation__main_filteroption_wrap h2 {
    font-size: 21px;
    font-weight: 500;
}

.page__container_wrapper.userCreations__page .userCreation__main_filteroption_fields .filter__option_col h6 {
    width: 100%;
    max-width: 160px;
    margin-bottom: 6px;
}

.page__container_wrapper.userCreations__page .filter__option_col input[type="date"] {
    margin: 0;
}

.page__container_wrapper.userCreations__page .filter__option_col select {
    width: 100%;
    border: 1px solid #292D4180;
    border-radius: 8px;
}
.userCreation__main_filteroption_fields .filter__option_col {
    display: flex;
    flex-wrap: wrap;
}

.userCreation__main_filteroption_fields .filter__option_col input[type="date"] {
    margin: 0 !important;
}
.diabledButton{
    pointer-events: none !important;
}