.cash__vander_main .heading-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 25px;
  border-bottom: 1px solid #292d411f;
  align-items: center;
  margin-bottom: 30px;
}

h2.cash-heading {
  font-size: 21px;
  color: #292d41;
  font-weight: 600;
  margin: 0;
}

.cash__vander_main .heading-container select.select-container {
  border: 1px solid #292d4180;
  border-radius: 8px;
  font-size: 12px;
  width: 100%;
  max-width: 290px;
  height: 40px;
}

.cash__vander_main .cash-form {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  column-gap: 70px;
}

.cash__vander_main .total-container {
  width: 100%;
  padding-top: 27px;
  margin-top: 30px;
  border-top: 1px solid #292d411f;
}

.cash__vander_main .total-container p {
  font-size: 14px;
  max-width: 160px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 7px;
}

.cash__vander_main .total-container p span {
  font-size: 21px;
  color: #ff6c36;
}

.country-container label {
  width: 100%;
  font-size: 14px;
  color: #292d41;
  opacity: 0.6;
}

.country-container {
  display: flex;
  flex-wrap: wrap;
  column-gap: 10px;
}

.country-container #phone {
  width: 95px;
}

.country-container button#rfs-btn {
  padding: 0;
  height: 40px;
  margin-right: 20px;
  padding-right: 10px;
  font-size: 13px !important;
  border: 1px solid #292d4180;
  border-radius: 8px;
}

span.ReactFlagsSelect-module_selectFlag__2q5gC {
  font-size: 21px;
}

.country-container .ReactFlagsSelect-module_selectValue__152eS {
  padding-right: 0;
}

.country-container input {
  border: 1px solid #292d4180;
  border-radius: 8px;
  font-size: 12px;
  width: 100%;
  max-width: 250px;
  height: 40px;
  padding-left: 5px;
}

.input-element {
  display: flex;
  flex-wrap: wrap;
  max-width: 300px;
}

.input-element label {
  width: 100%;
  font-size: 14px;
  color: #292d41;
  opacity: 0.6;
  margin-top: 23px;
}

.input-element input {
  border: 1px solid #292d4180;
  border-radius: 8px;
  font-size: 12px;
  width: 100%;
  max-width: 290px;
  height: 40px;
  padding-left: 5px;
}

h3.cash__form_title {
  font-size: 21px;
  color: #292d41;
  font-weight: 600;
  margin: 0 0 20px 0;
  width: 100%;
}

span.ReactFlagsSelect-module_selectFlag__2q5gC {
  font-size: 21px;
}

.cash__form_right_seatField {
  width: calc(100% - 370px);
  /* background: #F9F9FC 0% 0% no-repeat padding-box; */
  border-radius: 20px;
  /* padding: 22px; */
  min-height: 660px;
}

.cash__form_left_fields {
  width: 300px;
}

.zoom__main_box .fas.zoom-icon {
  color: #00b3e3;
  font-size: 25px;
  margin-right: 20px;
}

.country-container.inputformPhone {
  position: relative;
}

.country-container.inputformPhone ul.suggestions {
  padding: 0;
  list-style: none;
  position: absolute;
  right: 13px;
  top: 100%;
  width: 183px;
  padding: 3px 8px;
  border: 1px solid #292d4180;
  border-radius: 9px;
  margin-top: -4px;
  font-size: 12px;
  background: #e5e5e5;
}

.country-container.inputformPhone ul.suggestions li {
  border-bottom: 1px solid #dbdbdb;
  padding: 2px 0;
}

.country-container.inputformPhone ul.suggestions li:last-child {
  border: none;
}

h2.cash-heading.ordSum {
  font-size: 12px;
  width: 150px;
  margin: 0;
  margin-right: 5px;
}

.close__delete {
  display: inline-flex;
  width: 18px;
  height: 18px;
  color: #ff6c36;
  border: 1px solid #ff6c36;
  justify-content: center;
  align-items: center;
  border-radius: 18px;
  transform: rotate(45deg);
}

.seating-box-wrapper {
  width: 100%;
  background: #f9f9fc 0% 0% no-repeat padding-box;
  border-radius: 25px;
  padding: 25px;
  width: 100%;
  position: relative;
}

.seating-box-wrapper .refresh-btn {
  position: absolute;
  background: transparent;
  border: none;
  top: 0;
  right: 0;
  margin: 10px;
  cursor: pointer;
}

.seating-box-wrapper .sections-wrapper {
  width: 600px;
  max-width: 100%;
  margin: 0 auto;
  margin-bottom: 30px;
}

.seating-box-wrapper .sections-wrapper h4 {
  text-align: center;
  font-size: 25px;
  width: 100%;
  font-weight: 700;
}

.seating-box-wrapper .sections-wrapper p,
.seating-box-wrapper .seats-wrapper p {
  text-align: center;
  color: #66707e;
}

.seating-box-wrapper .sections-wrapper .section-list {
  display: flex;
  /* justify-content: space-between; */
}

.seating-box-wrapper .sections-wrapper .bay-street {
  padding: 15px 0;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #626576;
}

.seating-box-wrapper .sections-wrapper .bay-street span {
  margin: 0 20px;
}

.seating-box-wrapper .sections-wrapper ul {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  min-width: 80px;
  padding: 0;
}
.seating-box-wrapper .sections-wrapper ul.sections-list {
  flex-wrap: wrap;
  justify-content: center;
}

.seating-box-wrapper .sections-wrapper ul.nth-first,
.seating-box-wrapper .sections-wrapper ul.nth-forth {
  width: 30%;
}

.seating-box-wrapper .sections-wrapper ul.nth-second {
  width: 30%;
}

.seating-box-wrapper .sections-wrapper ul.nth-fifth {
  width: calc(30% + 60px);
}

.seating-box-wrapper .sections-wrapper ul.nth-third {
  width: 40%;
}

.seating-box-wrapper .sections-wrapper ul li {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  font-weight: 600;
  width: 35px;
  height: 35px;
  border: 1px solid #606060;
  cursor: default;
  opacity: 0.5;
}

.seating-box-wrapper .sections-wrapper ul li.enabled {
  cursor: pointer;
  opacity: 1;
}

.seating-box-wrapper .seats-wrapper .ticket-availability {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px 0 25px;
}

.seating-box-wrapper .seats-wrapper .ticket-availability li {
  display: flex;
  align-items: center;
  margin-right: 25px;
  font-size: 15px;
  color: #747782;
}

.seating-box-wrapper .seats-wrapper .ticket-availability li:last-child {
  margin-right: 0;
}

.seating-box-wrapper .seats-wrapper .ticket-availability li span {
  width: 10px;
  height: 10px;
  margin-right: 5px;
  display: inline-block;
  border-radius: 50px;
}

.seats-listing-wrapper {
  overflow: auto;
  max-height: 420px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.seating-box-wrapper .seats-row-wrapper{
  position: sticky;
  left: 0;
}

.seating-box-wrapper .seats-row-wrapper .title {
  font-size: 15px;
  color: #000;
  width: 20px;
  height: 30px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  background: #fff;
}

.seating-box-wrapper .seats-wrapper .seats-row {
  display: flex;
  margin-bottom: 10px;
}

.seating-box-wrapper .seats-wrapper .seats-row * {
  flex: none;
}

.seating-box-wrapper .seats-wrapper .seats-row .seat {
  width: 30px;
  height: 30px;
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  justify-content: center;
  border: 1px solid #000;
  margin-right: 10px;
  border-radius: 8px;
  cursor: pointer;
}

.seating-box-wrapper .seats-wrapper .seats-row .seat.disabled {
  cursor: default;
}

.no-seats-available {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: bold;
  height: 350px;
}

.non-seating-box-wrapper .non-seating-box {
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
/* .non-seating-box-wrapper .non-seating-box .title-box {
  width: 300px;
} */
.non-seating-box-wrapper .remaining-tickets {
  /* position: absolute; */
  /* right: 15px;
  top: 5px; */
  font-size: 12px;
  color: #58677db2;
  width: 130px;
  text-align: right;
}
.non-seating-box-wrapper .remaining-tickets.sold {
  color: #ee354b;
}

.non-seating-box-wrapper h4 {
  font-size: 18px;
  color: #292d41;
  margin: 0;
  font-weight: bold;
}
.non-seating-box-wrapper .non-seating-box .title-box p {
  font-size: 14px;
  color: #292d41;
  margin: 0;
  font-weight: bolder;
}
.non-seating-box-wrapper h5 {
  font-size: 24px;
  color: #13aee1;
  margin: 0;
}

.non-seating-box-wrapper input {
  font-size: 18px;
  border-radius: 50px;
  border: 1px solid #f06b3c;
  width: 45px;
  height: 45px;
  text-align: center;
  margin: 0 15px;
}

.non-seating-box-wrapper .quanity-btn {
  background: transparent;
  border: none;
  font-size: 20px;
  border-radius: 50px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #13aee1;
  color: #fff;
}

.non-seating-box-wrapper .quanity-btn:first-child {
  font-size: 35px;
  padding-bottom: 5px;
}

.non-seating-box-wrapper .add-to-cart {
  background: #00b3e3;
  color: #fff;
  border: none;
  font-size: 15px;
  margin-left: 10px;
  border-radius: 8px;
  padding: 8px 20px;
}

@media (max-width: 760px) {
  .seating-box-wrapper .sections-wrapper {
    margin-bottom: 20px;
  }

  .seating-box-wrapper .sections-wrapper p,
  .seating-box-wrapper .seats-wrapper p {
    font-size: 13px;
  }

  .seating-box-wrapper .sections-wrapper .bay-street {
    padding: 10px 0;
    font-size: 14px;
  }

  .seating-box-wrapper .sections-wrapper .bay-street span {
    margin: 0 15px;
  }

  .seating-box-wrapper .sections-wrapper ul {
    gap: 5px;
    min-width: auto;
  }

  .seating-box-wrapper .sections-wrapper ul li {
    width: 25px;
    height: 25px;
    font-size: 13px;
  }

  .seating-box-wrapper .seats-wrapper .ticket-availability li {
    margin-right: 15px;
    font-size: 12px;
  }

  .seating-box-wrapper .seats-wrapper .ticket-availability li span {
    width: 8px;
    height: 8px;
    margin-right: 5px;
  }

  .seating-box-wrapper .seats-wrapper .seats-row {
    margin-bottom: 5px;
  }

  .seating-box-wrapper .seats-wrapper .seats-row .title {
    font-size: 12px;
    width: 10px;
    height: 24px;
    display: flex;
    align-items: center;
  }

  .seating-box-wrapper .seats-wrapper .seats-row .seat {
    width: 20px;
    height: 20px;
    font-size: 11px;
    margin-right: 3px;
    border-radius: 4px;
  }
}
