.userCreations__page_header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 40px;
}

.userCreations__page_header h2 {
  font-size: 21px;
  font-weight: 600;
  display: flex;
  align-items: center;
  margin-bottom: 0;
}

.userCreations__page_header h2 span.addnew__newsUser_btn {font-size: 14px;color: #00B3E3;font-weight: 100;display: inline-flex;align-items: center;line-height: 1.2;margin-left: 30px;cursor: pointer;}

span.addnew__newsUser_btn img {
  width: 18px;
  height: 18px;
  margin-right: 10px;
}

.page__container_wrapper.userCreations__page h6 {
  margin: 0;
}

.page__container_wrapper.userCreations__page .filter__option_col input[type="date"] {
  border: 1px solid #292D4180;
  border-radius: 8px;
  height: 30px;
  color: #292D41;
  font-size: 12px;
  width: 100%;
  min-width: 125px;
  padding: 0 6px;
  margin-left: 10px;
}

.userCreation__main_table_container {
  width: 100%;
}

table.userCreation__main_table_box {
  width: 100%;
}

table.userCreation__main_table_box thead {background-color: #f2f2f2;}

table.userCreation__main_table_box thead th {
  font-size: 12px;
  border: none;
  background: transparent;
  padding: 15px;
}

table.userCreation__main_table_box td {
  padding: 20px 15px;
  border-bottom: 1px solid #ddd;
}

.userCreation__main_table_options__button {
  text-align: center;
  cursor: pointer;
}

.userCreation__main_table_menu_dropdown {
  position: absolute;
  right: 13px;
  top: 0;
  background: #fff;
  box-shadow: 0px 10px 16px #D8E0ED33;
  border-radius: 5px;
}

.userCreation__main_table_options {
  position: relative;
  cursor: pointer;
}

ul.userCreation__main_table_menu_options {
  padding: 6px 6px 0px 6px;
  margin: 0;
  list-style: none;
}

ul.userCreation__main_table_menu_options li {
  margin-bottom: 6px;
  font-size: 12px;
}

ul.userCreation__main_table_menu_options li {
  cursor: pointer;
}

form.add__user_form_field_form {
  display: flex;
  flex-wrap: wrap;
  column-gap: 30px;
}

.add__user_form_field {
  width: 31%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 40px;
}

.add__user_form_field label {
  padding: 0;
  width: 100%;
  font-size: 14px;
  color: #292D41;
  opacity: 0.6;
}

.add__user_form_field input[type="text"],
.add__user_form_field input[type="tel"] {
  width: 100%;
  border: 1px solid #292D4180;
  border-radius: 8px;
  height: 40px;
  font-size: 12px;
}

.add__user_form_field select {
  width: 100%;
  border: 1px solid #292D4180;
  border-radius: 8px;
  height: 40px;
  font-size: 12px;
  text-indent: 10px;
}

.add__user_form_field_checkbox {
  width: 100%;
  display: flex;
  column-gap: 40px;
  margin-bottom: 40px;
}

.add__user_form_field_checkbox label.checkbox-label {
  padding: 0;
  text-transform: capitalize;
  font-size: 12px;
  display: flex;
  align-items: inherit;
}

.add__user_form_field_checkbox label.checkbox-label input {
  margin-right: 10px;
}

.add__user_form_field_checkbox label {
  padding: 0;
  font-size: 12px;
}

.add__user_form_field_checkbox input.create-event {
  margin-right: 10px;
}

.add__user_form_field_checkbox_listing {
  width: 100%;
  display: flex;
  column-gap: 40px;
  margin-bottom: 40px;
  align-items: center;
}

.add__user_form_field_checkbox_listing label {
  padding: 0;
  font-size: 12px;
}

.add__user_form_field_checkbox_listing>label input {
  margin-right: 10px;
  font-size: 12px;
}

.add__user_form_field_checkbox_condition input.number-of-events {
  margin-left: 10px;
  border: 1px solid #292D4180;
  border-radius: 8px;
  height: 40px;
  font-size: 12px;
  min-width: 143px;
  margin-right: 30px;
}

.add__user_form_field_checkbox_listing label select.time-limit {
  border: 1px solid #292D4180;
  border-radius: 8px;
  height: 40px;
  font-size: 12px;
  text-indent: 10px;
  min-width: 143px;
  margin-left: 10px;
}


button.add__user_form_field_button_add_user {
  color: #fff;
  font-size: 18px;
  border: 1px solid #00B3E3;
  border-radius: 25px;
  width: 180px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: #00B3E3;
}

button.add__user_form_field_button_cancel {
  background: no-repeat;
  border: none;
  padding: 0;
  color: #292D41;
}

.add__user_form_field_button {
  display: flex;
  align-items: center;
  column-gap: 30px;
}
.add__user_title_heading {
  width: 100%;
  font-size: 21px;
  font-weight: 700;
  margin-bottom: 30px;
} 
.edit__user_table_wrap {
  margin-top: 45px;
}
td.confirmation-message {
  background: #F44040;
  box-shadow: 0px 4px 9px #F440403E;
  border-radius: 13px;
  color: #fff;
  font-size: 14px;
  text-align: center;
}

td.confirmation-message .delete-container button {
  border: 1px solid #FFFFFF;
  border-radius: 25px;
  background: transparent;
  color: #fff;
  line-height: 1;
  font-size: 12px;
  width: 54px;
  height: 24px;
  margin-left: 19px;
}
.add__user_form_field_checkbox_condition .form-select {
  display: inline-block !important;
  width: fit-content;
  background: #fff !important;
}
.form-select:focus{
  border: 1px solid #292D4180  !important;
  box-shadow: none  !important;
}
.disbaledButton{
  pointer-events: none;
}