.leftSidebar__logo_navigation {
  position: fixed;
  top: 0;
  width: 30%;
  max-width: 220px;
  background: transparent;
  height: 100vh;
  overflow: auto;
  padding-top: 13px;
  z-index: 9999;
}

.header__logo_main {
  padding-left: 30px;
  margin-bottom: 25px;
}

.header__logo_main img {
  width: 100%;
  max-width: 88px;
}

/* .leftMenu__main_wrap a {
  padding: 12px 0 12px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
} */

.leftMenu__main_wrap a img {
  margin-right: 8px;
}
.leftMenu__main_wrap a:hover {
  background: #00b3e3;
  color: #fff;
  border-radius: 0 10px 10px 0;
}

.leftMenu__main_wrap a:hover img {
  filter: invert(100%) sepia(93%) saturate(2%) hue-rotate(337deg)
    brightness(112%) contrast(100%);
}

.leftMenu__main_wrap a span {
  width: 80%;
}

.cardnav-wrapper {
  width: 100%;
  max-width: 414px;
  padding: 0 33px;
  margin-top: 45px !important;
}

.main__container_appbody_wrap {
  display: flex;
  padding-left: 290px;
}

.appbody__main_container {
  width: 100%;
  max-width: 1126px;
}
.appbody__main_container .header_main_container {
  margin-left: 0;
  width: 100%;
}
.appbody__main_container .appbody__content_container {
  background: #fff;
  padding: 40px;
  border-radius: 0 0 30px 30px;
  margin-bottom: 50px;
}

.createvent__btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1px 23px;
  border-radius: 30px;
  border: 1px solid rgb(61, 187, 237);
  color: #fff;
  font-size: 18px;
  background: #00b3e3;
  font-weight: 100;
  height: 60px;
  margin-bottom: 40px;
  cursor: pointer;
}

.createvent__btn i.fa-solid.fa-plus {
  border: 2px solid #fff;
  border-radius: 17px;
  width: 28px;
  height: 28px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 6px;
}
.cardnav-wrapper h5.event-heading {
  font-size: 21px;
  font-weight: 600;
  margin-bottom: 18px;
}
.eventCard__card_col {
  background: #fff;
  border-radius: 40px;
  margin-bottom: 20px;
}

.eventCard__card_image {
  border-radius: 40px;
  overflow: hidden;
}

.eventCard__card_body {
  padding: 15px 30px;
}

h5.eventCard__card_event_cat {
  font-size: 12px;
  color: #ff6c36;
}

p.eventCard__card_event_title {
  font-size: 18px;
  color: #292d41;
  font-weight: 600;
}

.card_event_date_location_wrap {
  display: flex;
  margin-bottom: 15px;
}

.card_event_date_col {
  display: flex;
  align-items: center;
}

.card_event_date_col .card_event_date_details {
  display: flex;
  flex-wrap: wrap;
  color: #939393;
  font-size: 12px;
  line-height: 1.4;
  padding-left: 10px;
}

span.card_event_date_details_day {
  width: 100%;
}

.card_event_location_col {
  display: flex;
  align-items: center;
}

.card_event_location_details {
  display: flex;
  flex-wrap: wrap;
  color: #939393;
  font-size: 12px;
  line-height: 1.4;
  padding-left: 10px;
}

span.card_event_location_title {
  width: 100%;
}

span.card_event_location_vanue {
  width: 100%;
}
