.header__main_wrap{
    background-color: white;
  }

  
  .header__main_wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0px;
    border-bottom: 1px solid #ccc;
    margin: 0 44px;
    background: transparent;
  }
  .header_main_container {
    margin-left: 290px;
    margin-top: 40px;
    width: calc(60vw - 40px);
    background: #fff;
    padding-top: 20px;
    border-radius: 30px 30px 0 0;
    max-width: 1170px;
}
  .left {
    display: flex;
    align-items: center;
  }
  
  .search-icon {
    font-size: 20px;
    margin-right: 10px;
  }
  
  .search-input {
    border: none;
    border-radius: 4px;
    padding: 8px;
    outline: none;
    width: 100%;
  }
  .header__main_wrap i.fa-solid.fa-magnifying-glass {
    color: #444444;
    font-size: 20px;
}
  .right {
    display: flex;
    align-items: center;
  }
  .header__main_wrap .left {
    width: 50%;
}
  .right > * {
    margin-left: 30px;
  }
  
  .icon {
    font-size: 24px;
    margin: 0 10px;
    cursor: pointer;
  }
  
  .live-feed{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1px 23px;
    border-radius: 30px;
    border: 1px solid rgb(61, 187, 237);
    color: rgb(61, 187, 237);
    font-size: 18px;
    font-weight: bold;
  }
  .cardnav-wrapper {
    margin-top: -90px;
}
.live-feed>span {
  font-weight: 200;
}

  .live-feed > *{
    padding: 7px;
  }
  
  .live-feed:hover{
    background-color: rgb(61, 187, 237);
    color: white;
    cursor: pointer;
  }
  
  .notification {
    position: relative;
    color: #ff6c36;
    font-size: 24px;
  }
  .live-feed i.fa-solid.fa-play {
    padding: 0;
    font-size: 22px;
}
  .notification:after {
    content: "";
    display: block;
    position: absolute;
    width: 8px;
    height: 8px;
    background: #ff6c36;
    top: 0;
    right: 0;
    border-radius: 50%;
  }
  
  .profile-picture {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
  }
  body{background-color: transparent !important;}